import React, { useRef } from "react";
import { Text } from "../../../component/text/Text";
import { useTranslation } from "react-i18next";
import './RoundButton.css';

export const RoundButton = (props : {title: string, icon?: string, reverse?: boolean, className? : string, style? : any, txtStyle? : any, onClick : () => void}) : JSX.Element => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const { title, icon, reverse, className, style, txtStyle, onClick } = props;
    const {t} = useTranslation('common');

    const suffix = reverse ? '-reverse' : '';

    const getFontSize = () : string => {
        if(window.screen.availWidth <= 819){
          return '4vw';
        }
        else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
          return '2.5vw';
        }
        else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
          return '1vw';
        }
        else{
          return '1vw';
        }
    };

    const click = () => {
        console.log(`round-button-component${suffix}-selected`.toString());
        if(divRef.current&&divRef.current.className.includes(`round-button-component${suffix}-selected`.toString())){
            divRef.current.className=divRef.current.className.replace(`round-button-component${suffix}-selected`.toString(),`round-button-component${suffix}-clicked`.toString());
        }
        else if(divRef.current&&divRef.current.className.includes(`round-button-component${suffix}-clicked`.toString())){
            divRef.current.className=divRef.current.className.replace(`round-button-component${suffix}-clicked`.toString(),`round-button-component${suffix}-selected`.toString());
        }
        else if(divRef.current){
            divRef.current.className=`${divRef.current.className} round-button-component${suffix}-selected`.toString();
        }
        onClick();
    };

    const cl = className ? `round-button-component${suffix} ${className}`.toString() : `round-button-component${suffix}`.toString() ;
    const st = style ? style : {};
    const tStyle = txtStyle ? txtStyle : {};

    return(
        <div className={cl} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ...st}} onClick={click} ref={divRef} >
            {icon && <i className={icon} aria-hidden="true" style={{ fontSize : getFontSize() }} /> }
            <Text style={{marginLeft : icon ? '1vw' : '0vw', ...tStyle}} content={t(title)} size="sm" theme={reverse?'light':'dark'} />
        </div>
    );
};