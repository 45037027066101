import React, { useEffect, useRef, useState } from "react";
import { Text } from "../../component/text/Text";
import { useTranslation } from "react-i18next";
import { newId } from "../../utils/IdUtils";
import { Scroll } from "../../component/scroll/Scroll";
import { assetCategoryIcon, iPart } from "../../service/asset/Asset";
import { Pane } from "../../component/pane/Pane";
import { DECIMALS_18, ZERO, SUMMER_START_DATE, SUMMER_START_TIMESTAMP, EMPTY, DEBOUNCE_TIME } from "../../constant/constant";
import { Img } from "../../component/img/Img";
import { RoundButton } from "../../component/button/round/RoundButton";
import BigNumber from 'bn.js';
import { largeScreen } from "../../utils/ScreenUtils";
import { AllFilters, AreasOnCountry, loadAllFilters, loadAreasOnCountry, loadOffers, Offer } from "../../service/hostel/Hostel";
import { CardLayout } from "../../component/layout/card-layout/CardLayout";
import { merge } from "../../utils/CollectionUtils";
import { dateToMiddaySecondTimestamp } from "../../utils/DateUtils";

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import './Search.css';
import { Input } from "../../component/input/Input";

export interface SearchState extends AllFilters{
  placeActivePanel: number;
  placeEnabled : boolean;
  hostelCategoriesEnabled : boolean;
  offerCategoriesEnabled : boolean;
  dateEnabled: boolean;
  priceEnabled : boolean;

  countriesSelected : boolean[];
  areasSelected : boolean[];
  hostelCategoriesSelected : boolean[];
  offerCategoriesSelected : boolean[];
  priceSelected : BigNumber[];
  priceFormated : string[];

  arrivalDate : Dayjs;
  departureDate : Dayjs;
  flexible: boolean;


  filterLoaded : boolean;
};

const getFontSize = () : string => {
  if(window.screen.availWidth <= 819){
    return '2.5vw';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
    return '2.5vw';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
    return '1vw';
  }
  else{
    return '1vw';
  }
};

const getEntrySize = () : string => {
  if(window.screen.availWidth <= 819){
    return '6vw';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
    return '4vw';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
    return '2vw';
  }
  else{
    return '2vw';
  }
};

const getDatePickerWidth = () : string => {
  if(window.screen.availWidth <= 819){
    return '25vw';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
    return '20vw';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
    return '10vw';
  }
  else{
    return '10vw';
  }
};

const getScrollHeight = () : string => {
  if(window.screen.availWidth <= 819){
    return '55vw';
  }
  else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
    return '30vw';
  }
  else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
    return '25vw';
  }
  else{
    return '25vw';
  }
};


const SearchElement = ( props : {title : string, icon : string, filterName : string, valuesSelected: boolean[], values: string[], enabled : boolean, labelPrefix?: string, onClick : () => void,  onChecked : (i : number) => void, onSelect : () => Promise<void>, className : string, icons? : (value : string) => string}) : JSX.Element => {
  const {t} = useTranslation('common');
  const {valuesSelected, values, enabled, onClick, onChecked, onSelect, icon, icons, title, filterName, labelPrefix, className} = props;
  const ref = useRef<HTMLDivElement | null>(null);

  const onClickElement = () => {
    if(ref.current&&ref.current.className.includes('selected')){
      ref.current.className=ref.current.className.replace('selected','clicked');
    }
    else if(ref.current&&ref.current.className.includes('clicked')){
      ref.current.className=ref.current.className.replace('clicked','selected');
    }
    else if(ref.current){
      ref.current.className=`${ref.current.className} ${className}-selected`.toString();
    }
    onClick();
  };
  
  return (
    <div key={`search-${filterName}-${newId()}`} className={className} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={onClickElement} ref={ref} >
      <i className={icon} aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
      <Text style={{marginLeft : '1vw' }} content={t(title)} size="md" theme={'dark'} />
      {enabled && 
        <div className="search-component-dropdown" onClick={(e :any)=> {e.stopPropagation();}}>
          <Scroll maxHeight={getScrollHeight()}>
            {values.map((value :string,i : number) => (
              <Pane key={`${filterName}-key-${i}`} row={true} style={{height : getEntrySize()}}>
                <>
                { icons &&
                  <i className={icons(value)} aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
                }
                </>
                <Text style={{ width : largeScreen()? '30%': '50%', marginLeft : largeScreen()? '1vw':'0.2vw', marginRight : largeScreen()? '1vw':'0.2vw' }} content={labelPrefix? t(`${labelPrefix}.${value}`):value} size="sm" theme={'dark'} />
                <input style={{backgroundColor: 'green', padding : 0}} type="checkbox" name={`${filterName}-${i}`} checked={valuesSelected[i]} onChange={() => onChecked(i)} />
              </Pane>
            ))}
          </Scroll>
          <RoundButton title={t('page.marketplace.search.select')}  style={{marginTop : '2vw'}} onClick = {onSelect}/>
        </div>
      }
    </div>
  );
};


const SearchPlaceElement = ( props : {title : string, filterName : string, activeChild: number, countriesSelected: boolean[], countries: string[], areasSelected: boolean[], areas: string[], enabled : boolean, onClick : () => void,  onChecked : (i : number) => void, onSelect: () => Promise<void>, onReset : () => Promise<void>, className : string, icon? : (value : string) => string} ) : JSX.Element => {
  const {t} = useTranslation('common');
  const {countriesSelected, countries, areasSelected, areas, enabled, onClick, onChecked, onSelect, onReset, icon, title, filterName, activeChild, className} = props;
  const ref = useRef<HTMLDivElement | null>(null);

  const onClickElement = () => {
    if(ref.current&&ref.current.className.includes('selected')){
      ref.current.className=ref.current.className.replace('selected','clicked');
    }
    else if(ref.current&&ref.current.className.includes('clicked')){
      ref.current.className=ref.current.className.replace('clicked','selected');
    }
    else if(ref.current){
      ref.current.className=`${ref.current.className} ${className}-selected`.toString();
    }
    onClick();
  };

  const height = getScrollHeight();
  return (
    <div key={`search-${filterName}-${newId()}`} className={className} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={onClickElement} ref={ref} >
      <i className="fa-solid fa-globe" aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
      <Text style={{marginLeft : '1vw' }} content={t(title)} size="md" theme={'dark'} />
      {enabled && 
        <div className="search-component-dropdown" onClick={(e :any)=> {e.stopPropagation();}}>
          <CardLayout activeChild={activeChild} style={{maxHeight : height, height: height, display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems: 'center'}}>
              <Scroll maxHeight={height}>
                {countries.map((country,i) => (
                  <Pane key={`${filterName}-key-${i}`} row={true} style={{height : getEntrySize()}}>
                    <>
                    { icon &&
                      <i className={icon(country)} aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
                    }
                    </>
                    <Text style={{ width : largeScreen()? '30%': '50%', marginLeft : largeScreen()? '1vw':'0.2vw', marginRight : largeScreen()? '1vw':'0.2vw' }} content={country} size="sm" theme={'dark'} />
                    <input style={{backgroundColor: 'green', padding : 0}} type="checkbox" name={`${filterName}-${i}`} checked={countriesSelected[i]} onChange={() => onChecked(i)} />
                  </Pane>
                ))}
              </Scroll>
              <Scroll maxHeight={height}>
                {areas.map((area,i) => (
                  <Pane key={`${filterName}-key-${i}`} row={true} style={{height : getEntrySize()}}>
                    <>
                    { icon &&
                      <i className={icon(area)} aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
                    }
                    </>
                    <Text style={{ width : largeScreen()? '30%': '50%', marginLeft : largeScreen()? '1vw':'0.2vw', marginRight : largeScreen()? '1vw':'0.2vw' }} content={area} size="sm" theme={'dark'} />
                    <input style={{backgroundColor: 'green', padding : 0}} type="checkbox" name={`${filterName}-${i}`} checked={areasSelected[i]} onChange={() => onChecked(i)} />
                  </Pane>
                ))}
              </Scroll>
          </CardLayout>
          { activeChild===0 ?
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0}}>
              <RoundButton title={t('page.marketplace.search.select')}  style={{marginTop : '2vw'}} onClick = {onSelect}/>
            </div>
            :
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0}}>
              <RoundButton title={t('page.marketplace.content.nft.back')} reverse={true} style={{marginTop : '2vw'}} onClick = {onReset}/>
              <RoundButton title={t('page.marketplace.search.select')}  style={{marginTop : '2vw'}} onClick = {onSelect}/>
            </div>
          }
        </div>
      }
    </div>
  );
};

const SearchPeriodElement = ( props : {title : string, enabled : boolean, arrivalDate : Dayjs, departureDate : Dayjs, flexible: boolean, onClick : () => void,  onPeriodSelected : (arrivalDate : Dayjs, departureDate : Dayjs, flexible: boolean) => void,  onSelect : () => Promise<void>, className : string}) : JSX.Element => {
  const {t} = useTranslation('common');
  const {enabled, onClick, onPeriodSelected, onSelect, arrivalDate, departureDate, flexible, title, className} = props;
  const ref = useRef<HTMLDivElement | null>(null);

  const onClickElement = () => {
    if(ref.current&&ref.current.className.includes('selected')){
      ref.current.className=ref.current.className.replace('selected','clicked');
    }
    else if(ref.current&&ref.current.className.includes('clicked')){
      ref.current.className=ref.current.className.replace('clicked','selected');
    }
    else if(ref.current){
      ref.current.className=`${ref.current.className} ${className}-selected`.toString();
    }
    onClick();
  };
  
  return (
    <div key={`search-period-${newId()}`} className={className} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={onClickElement} ref={ref} >
      <i className="fa fa-calendar" aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
      <Text style={{marginLeft : '1vw' }} content={t(title)} size="md" theme={'dark'} />
      {enabled && 
        <div className="search-component-dropdown" onClick={(e :any)=> {e.stopPropagation();}}>
          
            <Scroll maxHeight={getScrollHeight()}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Pane key="arrival-date" row={true} style={{marginTop : '1vw', marginBottom : '1vw'}}>
                <DatePicker
                  sx={{
                    width: getDatePickerWidth(),
                    color: 'success.main',
                  }}
                  label="Arrival date"
                  value={arrivalDate}
                  onChange={(newArrivalDate : Dayjs | null) => {
                    if(newArrivalDate) onPeriodSelected(newArrivalDate, departureDate, flexible);
                  }}
                />
              </Pane>
              <Pane key="departure-date" row={true} style={{marginTop : '1vw', marginBottom : '1vw'}}>
                <DatePicker
                  sx={{
                    width: getDatePickerWidth(),
                    color: 'success.main',
                  }}
                  label="Departure date"
                  value={departureDate}
                  onChange={(newDepartureDate : Dayjs | null) => {
                    if(newDepartureDate) onPeriodSelected(arrivalDate, newDepartureDate, flexible);
                  }}
                />
              </Pane>
              <Pane key="is-flexible" row={true} style={{marginTop : '1vw', marginBottom : '1vw'}}>
                <Text style={{ width : largeScreen()? '30%': '50%', marginLeft : largeScreen()? '1vw':'0.2vw', marginRight : largeScreen()? '1vw':'0.2vw' }} content={t('page.marketplace.search.flexible')} size="sm" theme={'dark'} />
                <input style={{backgroundColor: 'green', marginRight : '0.5vw', padding : 0}} type="checkbox" name="flexible" checked={flexible} onChange={() => onPeriodSelected(arrivalDate, departureDate, !flexible)} />
              </Pane>
              </LocalizationProvider>
            </Scroll>
          
          <RoundButton title={t('page.marketplace.search.select')}  style={{marginTop : '2vw'}} onClick = {onSelect}/>
        </div>
      }
    </div>
  );
};

export const Search = (props : { prices? : BigNumber[], onFilter : (arrivalDate : Dayjs, departureDate: Dayjs, offers : Offer[]) => void}) : JSX.Element => {

    const { prices, onFilter } = props;

    let timer : any = []; 

    const priceDivRef = useRef<HTMLDivElement | null>(null);

    const [searchState, setSearchState] = useState<SearchState>({
      id : '',

      countries : [],
      areas : [],
      hostelCategories : [],
      offerCategories : [],

      placeActivePanel: 0,
      placeEnabled : false,
      hostelCategoriesEnabled : false,
      offerCategoriesEnabled : false,
      dateEnabled: false,
      priceEnabled : false,

      countriesSelected : [],
      areasSelected : [],
      hostelCategoriesSelected : [],
      offerCategoriesSelected : [],
      priceSelected : [],
      priceFormated : [],

      arrivalDate : dayjs(SUMMER_START_DATE),
      departureDate : dayjs(SUMMER_START_DATE).add(1, 'day'),
      flexible: false, 

      filterLoaded: false
    } as SearchState);

    const { 
      id,
      countries,
      areas,
      hostelCategories,
      offerCategories,

      placeActivePanel,
      placeEnabled,
      hostelCategoriesEnabled,
      offerCategoriesEnabled,
      dateEnabled,
      priceEnabled,

      countriesSelected,
      areasSelected,
      hostelCategoriesSelected,
      offerCategoriesSelected,
      priceSelected, 
      priceFormated,

      arrivalDate,
      departureDate,
      flexible, 

      filterLoaded
    } = searchState;

    useEffect(() => {
      loadState();
    }, [JSON.stringify(prices)]); 

    const loadState = async () : Promise<void> => {
      try{
        const allFilters = await loadAllFilters();
        console.log(allFilters);
        const {id, countries, areas, hostelCategories, offerCategories } = allFilters;
        setSearchState({    
          ...searchState,  
          id,

          countries,
          areas,
          hostelCategories,
          offerCategories,
    
          placeEnabled : false,
          hostelCategoriesEnabled : false,
          offerCategoriesEnabled : false,
          priceEnabled : false,
    
          countriesSelected : countries.map(c => false),
          areasSelected : areas.map(a => false),
          hostelCategoriesSelected : hostelCategories.map(h => false),
          offerCategoriesSelected : offerCategories.map(o => false),
          priceSelected : prices ? [...prices] : [ZERO,ZERO],
          priceFormated : prices ? prices.map(p => iPart(p)) : [ZERO.toString(),ZERO.toString()],

          arrivalDate: dayjs(SUMMER_START_DATE),
          departureDate: dayjs(SUMMER_START_DATE).add(1, 'day'),
          flexible: false, 

          filterLoaded: false
        });
      }
      catch(e : any){
        console.error('Error while loading Filters: ',e);
      }
    };

    const {t} = useTranslation('common');

    const clickHostelCategory = () => {
      setSearchState({...searchState , hostelCategoriesEnabled: !hostelCategoriesEnabled, offerCategoriesEnabled: false, placeEnabled: false, dateEnabled: false, priceEnabled: false});
    };

    const clickOfferCategory = () => {
      setSearchState({...searchState , offerCategoriesEnabled: !offerCategoriesEnabled, hostelCategoriesEnabled: false, placeEnabled: false, dateEnabled: false, priceEnabled: false});
    };

    const clickPlace = () => {
      setSearchState({...searchState , hostelCategoriesEnabled: false, offerCategoriesEnabled: false, placeEnabled: !placeEnabled, dateEnabled: false, priceEnabled: false});
    };

    const clickDate = () => {
      setSearchState({...searchState , hostelCategoriesEnabled: false, offerCategoriesEnabled: false, placeEnabled: false, dateEnabled: !dateEnabled, priceEnabled: false});
    };
 

    const clickPrice = () => {
      if(priceDivRef.current&&priceDivRef.current.className.includes(`search-component-right-selected`.toString())){
        priceDivRef.current.className=priceDivRef.current.className.replace(`search-component-right-selected`.toString(),`search-component-right-clicked`.toString());
      }
      else if(priceDivRef.current&&priceDivRef.current.className.includes(`search-component-right-clicked`.toString())){
        priceDivRef.current.className=priceDivRef.current.className.replace(`search-component-right-clicked`.toString(),`search-component-right-selected`.toString());
      }
      else if(priceDivRef.current){
        priceDivRef.current.className=`${priceDivRef.current.className} search-component-right-selected`.toString();
      }
      setSearchState({...searchState , hostelCategoriesEnabled: false, offerCategoriesEnabled: false, placeEnabled: false, dateEnabled: false, priceEnabled: !priceEnabled});
    };

    const onHostelCategorySelected = (i : number) => {
      hostelCategoriesSelected[i]=!hostelCategoriesSelected[i];
      setSearchState({...searchState, hostelCategoriesSelected });
    };

    const onOfferCategorySelected = (i : number) => {
      offerCategoriesSelected[i]=!offerCategoriesSelected[i];
      setSearchState({...searchState, offerCategoriesSelected });
    };

    const onPlaceSelected = async (i : number) : Promise<void> => {
      try{
        if(placeActivePanel==0){
          countriesSelected[i]=!countriesSelected[i];
          setSearchState({...searchState, countriesSelected, });
        }
        else{
          areasSelected[i]=!areasSelected[i];
          setSearchState({...searchState, areasSelected });
        }
      }
      catch(error : any){
        console.error('On place selected error: ',error);
      }
    };

    const onPeriodSelected = (newArrivalDate : Dayjs, newDepartureDate : Dayjs, flexible: boolean) => {
      const arrivalTimestamp = dateToMiddaySecondTimestamp(arrivalDate.toDate());
      const departureTimestamp = dateToMiddaySecondTimestamp(departureDate.toDate());
      const newArrivalTimestamp = dateToMiddaySecondTimestamp(newArrivalDate.toDate());
      const newDepartureTimestamp = dateToMiddaySecondTimestamp(newDepartureDate.toDate());

      if(newArrivalTimestamp < newDepartureTimestamp){
        setSearchState({...searchState, arrivalDate: newArrivalDate, departureDate: newDepartureDate, flexible});
      }
      else if(arrivalTimestamp !== newArrivalTimestamp){
        setSearchState({...searchState, arrivalDate: newArrivalDate, departureDate: newArrivalDate.add(1, 'day'), flexible});
      }
      else if(departureTimestamp !== newDepartureTimestamp){
        setSearchState({...searchState, arrivalDate: newDepartureDate.subtract(1, 'day'), departureDate: newDepartureDate, flexible});
      }

    };

    const onPrice = (i : number, event : any) => {
      try{
        const val = event.target.value ? event.target.value : EMPTY;
        let value = new BigNumber(parseInt(val,10)).mul(DECIMALS_18);
        if(value.gte(ZERO)){
          priceSelected[i] = value;
          priceFormated[i] = iPart(priceSelected[i]);
          priceConsistency(i);
          setSearchState({...searchState , priceSelected , priceFormated});
        }
      }
      catch(error : any){
          console.error('Price error: ',error);
      }
    };

    const priceConsistency = (i : number) : void => {
      if(i == 0 && priceSelected[0].gte(priceSelected[1])){
        priceSelected[1] = priceSelected[0].add(priceSelected[0].mul(new BigNumber(2)).div(new BigNumber(100)));
        priceFormated[1] = iPart(priceSelected[1]);
      }
      if(i == 1 && priceSelected[1].lt(priceSelected[0])){
        const val = priceSelected[1].sub(priceSelected[1].mul(new BigNumber(2)).div(new BigNumber(100)));
        if(val.gte(ZERO)){
          priceSelected[0] = val;
          priceFormated[0] = iPart(priceSelected[0]);
        }
        else{
          priceSelected[0] = priceSelected[1];
          priceFormated[0] = iPart(priceSelected[0]);
        }
      }
    }


    const increasePrice = (i : number) => {
      let val = priceSelected[i].add(priceSelected[i].mul(new BigNumber(2)).div(new BigNumber(100)));
      priceSelected[i] = val;
      priceFormated[i] = iPart(priceSelected[i]);
      priceConsistency(i);
      setSearchState({...searchState , priceSelected});
    };

    const decreasePrice = (i : number) => {
      let val = priceSelected[i].sub(priceSelected[i].mul(new BigNumber(2)).div(new BigNumber(100)));
      if(val.gte(ZERO)){
        priceSelected[i] = val;
        priceFormated[i] = iPart(priceSelected[i]);
        priceConsistency(i);
        setSearchState({...searchState , priceSelected});
      }
    };

    const debounce = (event : any, callback : (v : any) => void, waitTime : number) => {
      clearTimeout(timer[0]);
      const { target, relatedTarget } = event;
      const val = target.value ? target.value : EMPTY;
      timer[0] = setTimeout(() => {
          callback(val);
          if (relatedTarget === null) {
            target.focus();
          }
      }, waitTime);
    };

    const onSelect = async () : Promise<void> => {
      try{

        const filteredCountries : string[] = [];
        countries.forEach((country,i) => {
          if(countriesSelected[i]){
            filteredCountries.push(country);
          }
        });

        if(placeActivePanel==0 && filteredCountries.length>0){

          console.log('FILTERED COUNTRIES: ',filteredCountries);

          let allAreas : string[] = [];
          const areasOnCountries : AreasOnCountry[] = await loadAreasOnCountry(filteredCountries);
          areasOnCountries.forEach((areasOnCountry,i) => {
              const { areas } = areasOnCountry;
              allAreas = merge(allAreas,areas);
          });

          console.log('ALL AREAS: ',allAreas);

          setSearchState({...searchState, countriesSelected, areas: allAreas, areasSelected: allAreas.map(a => false), placeActivePanel: 1});
        }
        else{
          const filteredHostelCategories : string[] = [];
          hostelCategories.forEach((category,i) => {
            if(hostelCategoriesSelected[i]){
              filteredHostelCategories.push(category);
            }
          });
    
          const filteredCountries : string[] = [];
          countries.forEach((country,i) => {
            if(countriesSelected[i]){
              filteredCountries.push(country);
            }
          });
  
          const filteredAreas : string[] = [];
          areas.forEach((area,i) => {
            if(areasSelected[i]){
              filteredAreas.push(area);
            }
          });
  
          const filteredOfferCategories : string[] = [];
          offerCategories.forEach((offerCategory,i) => {
            if(offerCategoriesSelected[i]){
              filteredOfferCategories.push(offerCategory);
            }
          });

          const arrivalTimestamp = new BigNumber(dateToMiddaySecondTimestamp(arrivalDate.toDate()));
          const departureTimestamp = new BigNumber(dateToMiddaySecondTimestamp(departureDate.subtract(1,'day').toDate()));

          console.log('---------------------FILTER----------------------');
          console.log(filteredHostelCategories);
          console.log(filteredCountries);
          console.log(filteredAreas);
          console.log(filteredOfferCategories);
          console.log(arrivalDate);
          console.log(arrivalTimestamp.toNumber());
          console.log('-------');
          console.log(SUMMER_START_TIMESTAMP.toNumber());
  
          const offersOnArrivalDate : Offer[] = await loadOffers(100, 0, filteredHostelCategories, filteredCountries, filteredAreas, filteredOfferCategories, arrivalTimestamp, []);
          const offersOnDepartureDate : Offer[] = await loadOffers(100, 0, filteredHostelCategories, filteredCountries, filteredAreas, filteredOfferCategories, departureTimestamp, []);

          const offersOnArrivalDateIds : string[] = offersOnArrivalDate.map(o => o.offerId.toString());
          const offersOnDepartureDateIds : string[] = offersOnDepartureDate.map(o => o.offerId.toString());

          let offers : Offer[] = [];
          if(flexible){
            [...offersOnDepartureDate, ...offersOnArrivalDate].forEach((offer : Offer)=> {
              if(!offersOnArrivalDateIds.includes(offer.offerId.toString()) || !offersOnArrivalDateIds.includes(offer.offerId.toString())){
                offers.push(offer);
              }
            });
          }
          else{
            offersOnArrivalDate.forEach((offer : Offer)=> {
              if(offersOnDepartureDateIds.includes(offer.offerId.toString())){
                offers.push(offer);
              }
            });
          }

          onFilter(arrivalDate, departureDate, offers);
          setSearchState({...searchState , hostelCategoriesEnabled: false, offerCategoriesEnabled: false, placeEnabled: false, dateEnabled: false, priceEnabled: false});
        }
      }
      catch(e : any){
        console.error('Filter select failed:',e);
      }
    };

    const onReset = async () : Promise<void> => {
      try{
        setSearchState({...searchState, countriesSelected, areas: [], areasSelected: [], placeActivePanel: 0 });
      }
      catch(e : any){
        console.error('Filter reset failed:',e);
      }
    };

    return(
        <div className="search-component-container" >
            <SearchElement 
              title="page.marketplace.search.category"
              filterName="hostelCategory"
              labelPrefix="page.marketplace.content.hostel-type"
              valuesSelected={hostelCategoriesSelected}
              values={hostelCategories} 
              enabled={hostelCategoriesEnabled}
              onClick={clickHostelCategory}
              onChecked={onHostelCategorySelected}
              onSelect={onSelect} 
              className="search-component-left" 
              icon="fa-solid fa-house"
              icons={assetCategoryIcon}
            />

            <SearchElement 
              title="page.marketplace.search.travelers"
              filterName="offerCategory"
              labelPrefix="page.marketplace.content.offer-type"
              valuesSelected={offerCategoriesSelected}
              values={offerCategories} 
              enabled={offerCategoriesEnabled}
              onClick={clickOfferCategory}
              onChecked={onOfferCategorySelected}
              onSelect={onSelect} 
              icon="fa-solid fa-users"
              className="search-component-middle"
            />

            <SearchPlaceElement 
              title="page.marketplace.search.place" 
              filterName="place"
              activeChild={placeActivePanel}
              countriesSelected={countriesSelected}
              countries={countries}
              areasSelected={areasSelected}
              areas={areas}
              enabled={placeEnabled}
              onClick={clickPlace}
              onChecked={onPlaceSelected}
              onSelect={onSelect}
              onReset={onReset}
              className="search-component-middle"
            />

            <SearchPeriodElement
              title="page.marketplace.search.date" 
              enabled={dateEnabled}
              arrivalDate={arrivalDate}
              departureDate={departureDate}
              flexible={flexible}
              onClick={clickDate}
              onPeriodSelected={onPeriodSelected}
              onSelect={onSelect} 
              className="search-component-middle"
            />

            <div key={`search-price-${newId()}`.toString()} className="search-component-right" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={clickPrice} ref={priceDivRef} >
              <i className="fa-solid fa-coins" aria-hidden="true" style={{ fontSize : getFontSize(), color : '#17824e' }} /> 
              <Text style={{marginLeft : '1vw', marginRight : '1vw' }} content={t('page.marketplace.search.price')} size="md" theme={'dark'} />
              {priceEnabled && 
                <div className="search-component-dropdown" onClick={(e :any)=> {e.stopPropagation();}}>
                  {largeScreen() ?
                    <>
                      <Pane key='price-key-0' row={true} style={{height : getEntrySize()}}>
                        <Text style={{marginLeft : '1vw' }} content={t('page.marketplace.search.min-price')} size="sm" theme={'dark'} />
                        <Img className="search-item-picture-md" src="images/token/jurakko-48.png" />
                        <Input type="button" className="search-item-button-border-md" value={t('page.marketplace.search.down')} onClick={(e : any) => decreasePrice(0)} />
                        <Input type="text" className="search-item-text-input-md" value={priceFormated[0]} onChange={(e : any) => onPrice(0,e)} />
                        <Input type="button" className="search-item-button-md" value={t('page.marketplace.search.up')} onClick={(e : any) => increasePrice(0)} />
                      </Pane>
                      <Pane key='price-key-1' row={true} style={{height : getEntrySize()}}>
                        <Text style={{marginLeft : '1vw' }} content={t('page.marketplace.search.max-price')} size="sm" theme={'dark'} />
                        <Img className="search-item-picture-md" src="images/token/jurakko-48.png" />
                        <Input type="button" className="search-item-button-border-md" value={t('page.marketplace.search.down')} onClick={(e : any) => decreasePrice(0)} />
                        <Input type="text" className="search-item-text-input-md" value={priceFormated[1]} onChange={(e : any) => onPrice(1,e)} />
                        <Input type="button" className="search-item-button-md" value={t('page.marketplace.search.up')} onClick={(e : any) => increasePrice(1)} />
                      </Pane>
                    </>
                    :
                    <>
                      <Pane key='price-key-0' row={true} style={{height : getEntrySize()}}>
                        <Text style={{marginLeft : '1vw' }} content={t('page.marketplace.search.min-price')} size="sm" theme={'dark'} />
                        <Img className="search-item-picture-md" src="images/token/jurakko-48.png" />
                      </Pane>
                      <Pane key='price-key-1' row={true} style={{height : getEntrySize()}}>
                        <Input type="button" className="search-item-button-border-md" value={t('page.marketplace.search.down')} onClick={(e : any) => decreasePrice(0)} />
                        <Input type="text" className="search-item-text-input-md" value={priceFormated[0]} onChange={(e : any) => onPrice(0,e)} />
                        <Input type="button" className="search-item-button-md" value={t('page.marketplace.search.up')} onClick={(e : any) => increasePrice(0)} />
                      </Pane>
                      <Pane key='price-key-2' row={true} style={{height : getEntrySize()}}>
                        <Text style={{marginLeft : '1vw' }} content={t('page.marketplace.search.max-price')} size="sm" theme={'dark'} />
                        <Img className="search-item-picture-md" src="images/token/jurakko-48.png" />
                      </Pane>
                      <Pane key='price-key-3' row={true} style={{height : getEntrySize()}}>
                        <Input type="button" className="search-item-button-border-md" value={t('page.marketplace.search.down')} onClick={(e : any) => decreasePrice(0)} />
                        <Input type="text" className="search-item-text-input-md" value={priceFormated[1]} onChange={(e : any) => onPrice(1,e)} />
                        <Input type="button" className="search-item-button-md" value={t('page.marketplace.search.up')} onClick={(e : any) => increasePrice(1)} />
                      </Pane>
                    </>
                  }
                  <RoundButton title={t('page.marketplace.search.select')} style={{marginTop : '2vw'}} onClick = {onSelect}/>
                </div>
              }
            </div>
        </div>
    );
};