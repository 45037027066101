import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BigNumber from 'bn.js';
import { connectedAccount, subscribeConnectEvent } from "../../../service/crypto/Crypto";
import { TabPanel, TabPanelItem } from "../../../component/tab-panel/TabPanel";
import { Scroll } from "../../../component/scroll/Scroll";
import { Img } from "../../../component/img/Img";
import { Text } from "../../../component/text/Text";
import { assetPictureId, formatAmount } from "../../../service/asset/Asset";
import SimpleViewMap, { MapData } from "../../../component/map/simple-view-map/SimpleViewMap";
import { Coordinate } from "ol/coordinate";
import { Offer } from "../../../service/hostel/Hostel";
import { timestampToDatetime } from "../../../utils/DateUtils";
import { DECIMALS_3, EMPTY } from "../../../constant/constant";
import { Connect } from "../../../component/connect/Connect";
import { RoundButton } from "../../../component/button/round/RoundButton";
import { Dayjs } from "dayjs";
import { BookingSummary } from "../booking/BookingSummary";
import { largeScreen } from "../../../utils/ScreenUtils";
import './NFT.css';

/**
 * @author Christophe Convert
 * 
 */
export interface NftState{
    account: string;
    activeLink: number,
    location: number[]
};

const getScrollHeight = () : string => {
    if(window.screen.availWidth <= 819){
      return '45vh';
    }
    else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return '40vh';
    }
    else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      return '40vh';
    }
    else{
      return '40vh';
    }
};

export const NFT = (props : {offer : Offer, arrivalDate: Dayjs, departureDate: Dayjs, onOfferSelected: (offer : Offer) => void}) : JSX.Element => {
    const { offer, arrivalDate, departureDate, onOfferSelected} = props;

    const [nftState, setNftState] = useState<NftState>({
        account : EMPTY,
        activeLink: 0,
        location: []
      } as NftState);
    
    const {t} = useTranslation('common');

    const tabAccountItems : TabPanelItem[] = [
        { title : t('page.account.categories.details'), icon : 'fas fa-sharp fa-light fa-coins'},
        { title : t('page.account.categories.book'), icon : 'fas fa-solid fa-wallet'},
        { title : t('page.account.categories.location'), icon : 'fas fa-solid fa-map'}
    ];

    const computeLocation = (offer: Offer) : number[] => {
        let location : number[] = [];
        try{
            location = [parseInt(offer.longitude.toString(),10),parseInt(offer.lattitude.toString(),10)]; 
        }
        catch(e : any){
            console.log(e);
        }
        return location;
    };

    const load = () => {
        if(offer){
            try{
                const account = connectedAccount();
                const location = computeLocation(offer);
                
                setNftState({ ...nftState, account, location }); 
            }
            catch(e : any){
                console.log(e);
            }
        }
    };

    useEffect(() => {
        subscribeConnectEvent((accounts : string[]) : void =>{
            load();
        });
        load();
    }, []); 

    const { activeLink, account, location } = nftState;

    return (
        <div className="nft-md" >
            <Img className="nft-picture-md" src={`nft/hostel/${assetPictureId(offer.pictureId.toNumber())}`.toString()} />
            <div className="nft-body-md">
                <TabPanel items={tabAccountItems} activeLink={activeLink} >
                    <>
                        <div className="nft-description-md">
                            <Scroll maxHeight={getScrollHeight()}>
                                <>
                                    {offer &&
                                        <>
                                            <Text content={`${offer.country}, ${offer.area}`} size="sm" theme="dark" bold={true} style={{ marginTop:largeScreen()?'1vh':'2vh', marginBottom:largeScreen()?'1vh':'2vh'}}/>
                                            
                                            <div className="nft-content">
                                                <div className="nft-content-transaction-group">
                                                    <Text content={t('page.marketplace.content.nft.host')} size="sm" theme="dark" />
                                                    <Text content={t('page.marketplace.content.nft.hostel-category')} size="sm" theme="dark" />
                                                    <Text content={t('page.marketplace.content.nft.offer-category')} size="sm" theme="dark" />
                                                    <Text content={t('page.marketplace.content.nft.night-price')} size="sm" theme="dark" />
                                                    <Text content={t('page.marketplace.content.nft.available')} size="sm" theme="dark" />
                                                    <Text content={t('page.marketplace.content.nft.period-from')} size="sm" theme="dark" />
                                                    <Text content={t('page.marketplace.content.nft.period-to')} size="sm" theme="dark" />
                                                </div>
                                                <div className="nft-content-transaction-group">
                                                    <Text content={offer.societyName} size="sm" theme="dark" />
                                                    <Text content={t(`page.marketplace.content.hostel-type.${offer.hostelCategory}`)} size="sm" theme="dark" />
                                                    <Text content={t(`page.marketplace.content.offer-type.${offer.offerCategory}`)} size="sm" theme="dark" />
                                                    <Text content={`${formatAmount(offer.nightUnitPrice)} JKO`.toString()} size="sm" theme="dark" />
                                                    <Text content={`${offer.available} / ${offer.quantity}`} size="sm" theme="dark" />
                                                    <Text content={`${timestampToDatetime(offer.periodTimestampStart.mul(DECIMALS_3).toNumber(),'DD-MM-YYYY')}`} size="sm" theme="dark" />
                                                    <Text content={`${timestampToDatetime(offer.periodTimestampEnd.mul(DECIMALS_3).toNumber(),'DD-MM-YYYY')}`} size="sm" theme="dark" />
                                                </div>
                                            </div>

                                            <Text content={offer.description} size="sm" theme="dark" style={{ marginTop:largeScreen()?'1vh':'2vh'}}/>
                                        </>
                                    }
                                </>
                            </Scroll>
                        </div>
                        <div className="nft-bottom-md">
                            <RoundButton title={t('page.marketplace.content.nft.buyOnUniswap')}  onClick = {() => {}} />
                        </div>
                    </>
                    
                    <>
                        { (offer && arrivalDate && departureDate) &&
                            <BookingSummary offer={offer} arrivalDate={arrivalDate} departureDate={departureDate} onOfferSelected={onOfferSelected}/>
                        }
                    </>

                    <>
                        <div className="nft-description-md">
                            {(offer && location) &&
                                <SimpleViewMap zoom={6} mapCenter={location} loader = {(bottomLeft: Coordinate, topRight: Coordinate) : Promise<MapData> => {
                                    return Promise.resolve({ location, title: offer.hostelName, imgPath: 'house-icon.png' });
                                }} />
                            }
                        </div>
                        <div className="nft-bottom-md">
                            <RoundButton title={t('page.marketplace.content.nft.buyOnUniswap')}  onClick = {() => {}} /> 
                        </div>
                    </>

                </TabPanel>
            </div>
        </div>
    )
};
