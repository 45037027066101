import React  from "react";
import { useTranslation } from "react-i18next";
import './Text.css';


export const Text = (props : { text? : string, content? : string, size? : 'sm' | 'md' | 'lg', theme? : 'light' | 'dark', bold? : boolean, className? : string, style? : any}) : JSX.Element => {
    const {t} = useTranslation('common');
    const {text, content, size, theme, bold, className, style}  = props;

    const cl = size && theme? `text-component-${size}-${theme} ${className?className:''}`.toString() : 'text-component-sm-dark';

    const data = text ? t(text) : (content ? content : '');

    return ( 
        <>
        {bold ?
            <div className={cl} style={style?style:{}}><b>{data}</b></div>
            :
            <div className={cl} style={style?style:{}}>{data}</div>
        }
        </>
    );
};
