import moment from 'moment';

const SECOND_IN_MILLIS = 1000;
const MINUTE_IN_SECOND = 60;
const HOUR_IN_SECOND = 3600;
export const DAY_IN_SECOND = 24*3600;


export const dateToMiddaySecondTimestamp = (date : moment.MomentInput, format? : moment.MomentFormatSpecification) : number  => {
    return (Math.trunc(moment(date, format ? format : "YYYY-MM-DD").unix() / DAY_IN_SECOND) + 1) * DAY_IN_SECOND + 12 * HOUR_IN_SECOND;
};


export const dateToTimestamp = (date : moment.MomentInput, format? : moment.MomentFormatSpecification) : number  => {
    return moment(date, format ? format : "YYYY-MM-DD").unix() * SECOND_IN_MILLIS;
};

export const dateToMidnightTimestamp = (date : moment.MomentInput, format? : moment.MomentFormatSpecification) : number  => {
    return Math.trunc(moment(date, format ? format : "YYYY-MM-DD").unix() / DAY_IN_SECOND) * DAY_IN_SECOND * SECOND_IN_MILLIS;
};

export const dateAndTimeToTimestamp = (date : moment.MomentInput, hour : number, minute : number, format? : moment.MomentFormatSpecification) : number  => {
    return dateToMidnightTimestamp(date,format) + (hour * HOUR_IN_SECOND + minute * MINUTE_IN_SECOND)*SECOND_IN_MILLIS;
};

export const dateToDatetime = (date : Date, format? : string) : string => {
    return moment(date).format(format ? format : 'YYYY-MM-DD, hh:mm:ss a');
};

export const timestampToDate = (timestamp : number) : Date => {
    return new Date(timestamp);
};

export const timestampToDatetime = (timestamp : number, format? : string) : string => {
    return moment(new Date(timestamp)).format(format ? format : 'YYYY-MM-DD, hh:mm:ss a');
};

export const timeToHour = (time : string) : number => {
    if(time.length!=5){
        return 0;
    } 
    return parseInt(time.substring(0, 2),10);
};

export const timeToMinutes = (time : string) : number => {
    if(time.length!=5){
        return 0;
    } 
    return parseInt(time.substring(3, 5),10);
};

export const timeToTimestampPart = (time : string) : number => {
    if(time.length!=5){
       return 0;
    } 
    const hour = timeToHour(time);
    const minute = timeToMinutes(time);
    const hourShift = 0;//time.substring(6, 8)=='PM' ? 12 : 0;
    return ((hour+hourShift)*HOUR_IN_SECOND + minute*MINUTE_IN_SECOND)*SECOND_IN_MILLIS;
};

export const nowAsTimestamp = () : number => {
    return moment().unix() * SECOND_IN_MILLIS;
};

export const asMidnightTimestamp = (timestamp : number) : number => {
    return Math.trunc((timestamp/SECOND_IN_MILLIS) / DAY_IN_SECOND) * DAY_IN_SECOND * SECOND_IN_MILLIS;
};

export const nowAsMidnightTimestamp = () : number => {
    return Math.trunc(moment().unix() / DAY_IN_SECOND) * DAY_IN_SECOND * SECOND_IN_MILLIS;
};

export const asTimeTimestamp = (timestamp : number) : number => {
    return ((timestamp/SECOND_IN_MILLIS) % DAY_IN_SECOND) * SECOND_IN_MILLIS;
};

export const nowAsTimeTimestamp = () : number => {
    return (moment().unix() % DAY_IN_SECOND) * SECOND_IN_MILLIS;
};

export const hour = (timestamp : number) : number => {
    return Math.trunc((timestamp % (DAY_IN_SECOND * SECOND_IN_MILLIS)) / (HOUR_IN_SECOND * SECOND_IN_MILLIS));
};

export const minutes = (timestamp : number) : number => {
    return Math.trunc(((timestamp % (DAY_IN_SECOND * SECOND_IN_MILLIS)) - hour(timestamp)* HOUR_IN_SECOND * SECOND_IN_MILLIS) / (MINUTE_IN_SECOND * SECOND_IN_MILLIS));
};

export const toTimestamp = (date: Date, time : string) : number => {
    return moment(moment(date).format('YYYY-MM-DD')+' '+time).unix()*SECOND_IN_MILLIS;
};
