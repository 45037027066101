import React, { useRef } from "react";
import './CircleButton.css';

export const CircleButton = (props : {icon: string, className? : string, style? : any, onClick : () => void}) : JSX.Element => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const { icon, className, style, onClick } = props;

    const getFontSize = () : string => {
        if(window.screen.availWidth <= 819){
          return '4vw';
        }
        else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
          return '2.5vw';
        }
        else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
          return '1vw';
        }
        else{
          return '1vw';
        }
    };

    const click = () => {
        if(divRef.current&&divRef.current.className.includes('circle-button-component-selected')){
            divRef.current.className=divRef.current.className.replace('circle-button-component-selected','circle-button-component-clicked');
        }
        else if(divRef.current&&divRef.current.className.includes('circle-button-component-clicked')){
            divRef.current.className=divRef.current.className.replace('circle-button-component-clicked','circle-button-component-selected');
        }
        else if(divRef.current){
            divRef.current.className=divRef.current.className+' circle-button-component-selected';
        }
        onClick();
    };

    const cl = className ? `circle-button-component ${className}`.toString() : "circle-button-component" ;
    const st = style ? style : {};

    return(
        <div className={cl} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ...st}} onClick={click} ref={divRef}>
            <i className={icon} aria-hidden="true" style={{ fontSize : getFontSize() }} />
        </div>
    );
};