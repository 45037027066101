
import React , { useEffect, useState }  from "react";
import dayjs, { Dayjs } from "dayjs";
import BigNumber from 'bn.js';
import { dateToDatetime, dateToMiddaySecondTimestamp, timestampToDatetime } from "../../../../utils/DateUtils";
import { formatAmount, percent, formatAddress, getEtherscanLink, loadPeriodOffers, Offer, approveBookingContract, bookAReservation, Booking, loadBookings, mintBill, cancelAReservation, clearCaches } from "../../../../service/hostel/Hostel";
import { Overlay } from "../../../../component/overlay/Overlay";
import { DateRange, RangeKeyDict } from "react-date-range";
import { CircleButton } from "../../../../component/button/circle/CircleButton";
import { RoundButton } from "../../../../component/button/round/RoundButton";
import { useTranslation } from "react-i18next";
import { largeScreen } from "../../../../utils/ScreenUtils";
import { Pane } from "../../../../component/pane/Pane";
import { CardLayout } from "../../../../component/layout/card-layout/CardLayout";
import { Text } from "../../../../component/text/Text";
import { EMPTY, DECIMALS_3, ZERO } from "../../../../constant/constant";
import { newIdAsNumber } from "../../../../utils/IdUtils";
import { getId, ID } from "../../../../service/asset/Asset";
import { connectedAccount, subscribeConnectEvent } from "../../../../service/crypto/Crypto";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './BookingView.css';


const START_STATE = 0;
const WAIT_STATE = 1;
const END_STATE = 2;

const BOOK_ACTION = 'book';
const BILL_ACTION = 'bill';
const CANCEL_ACTION = 'cancel';

/**
 * @author Christophe Convert
 * 
 */

const computePrice = (offer : Offer, numberDays : number) : BigNumber => {
    const feePlusVatPerTenThousand = new BigNumber(10000).add(new BigNumber(offer.vatForTenThousand)).add(new BigNumber(offer.feeForTenThousand));
    return offer.nightUnitPrice.mul(new BigNumber(numberDays)).mul(feePlusVatPerTenThousand).div(new BigNumber(10000));
};

const BookingScreen = (props : { offer? : Offer, selectedArrivalDate? : Dayjs, selectedDepartureDate? : Dayjs, disabledDates : number[], foc : 0 | 1, handleDateSelection : (range: RangeKeyDict) => void }) : JSX.Element => {
    const { offer, selectedArrivalDate, selectedDepartureDate, disabledDates, foc, handleDateSelection } = props;

    const {t} = useTranslation('common');
    console.log(props);

    let numberDays=0;
    if(selectedArrivalDate && selectedDepartureDate){
        numberDays = selectedDepartureDate.diff(selectedArrivalDate, 'days');
    }

    const disabledDay = (d : Date) : boolean => {
        return disabledDates.includes(dateToMiddaySecondTimestamp(d));
    };

    return (
        <>
            { largeScreen() ?
                <>
                    <Pane row={true} ratio="100">
                        <Text content={t('page.marketplace.content.nft.period-confirm')} size="md" bold={true} theme="dark" style={{marginBottom: '10vh'}}/>
                    </Pane>
                    <Pane row={true}>
                        <Pane ratio="50" row={true}>
                            <Pane ratio="50" style={{alignItems: 'flex-end'}}>
                                { offer &&
                                    <>
                                    <Text content={t('page.marketplace.content.nft.host')} size="md" theme="dark" />
                                    <Text content={t('page.marketplace.content.nft.hostel-category')} size="md" theme="dark" />
                                    <Text content={t('page.marketplace.content.nft.offer-category')} size="md" theme="dark" />
                                    <Text content={t('page.marketplace.content.hostel-vat')} size="md" theme="dark" />
                                    <Text content={t('page.marketplace.content.fee')} size="md" theme="dark" />
                                    <Text content={t('page.marketplace.content.nft.total-price')} size="md" theme="dark" />
                                    </>
                                }
                            </Pane>
                            <Pane ratio="50">
                                { offer &&
                                    <>
                                    <Text content={offer.societyName} size="md" theme="dark" />
                                    <Text content={t(`page.marketplace.content.hostel-type.${offer.hostelCategory}`)} size="md" theme="dark" />
                                    <Text content={t(`page.marketplace.content.offer-type.${offer.offerCategory}`)} size="md" theme="dark" />
                                    <Text content={`${percent(offer.vatForTenThousand)} %`.toString()} size="md" theme="dark" />
                                    <Text content={`${percent(offer.feeForTenThousand)} %`.toString()} size="md" theme="dark" />
                                    <Text content={`${formatAmount(offer.nightUnitPrice)} JKOx(${numberDays}) = ${formatAmount(computePrice(offer, numberDays))} JKO`.toString()} size="md" theme="dark" />
                                    </>
                                }
                            </Pane>
                        </Pane>
                        <Pane ratio="50">
                            <DateRange
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                retainEndDateOnFirstSelection={false}
                                calendarFocus="forwards"
                                onChange={handleDateSelection}
                                disabledDay={disabledDay}
                                ranges={[{
                                    startDate: selectedArrivalDate?selectedArrivalDate.toDate():undefined,
                                    endDate: selectedDepartureDate?selectedDepartureDate.toDate():undefined, 
                                    key: 'selection',
                                    color: 'green'
                                }]}
                                focusedRange={[0, foc]}
                            />
                        </Pane>
                    </Pane>
                </>   
                    :
                <>
                    <Pane row={true} ratio="100">
                        <Text content={t('page.marketplace.content.nft.period-confirm')} size="md" bold={true} theme="dark" style={{marginBottom: '10vh'}}/>
                    </Pane>
                    <Pane ratio="100">
                        <DateRange
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={false}
                            retainEndDateOnFirstSelection={false}
                            calendarFocus="forwards"
                            onChange={handleDateSelection}
                            disabledDay={disabledDay}
                            ranges={[{
                                startDate: selectedArrivalDate?selectedArrivalDate.toDate():undefined,
                                endDate: selectedDepartureDate?selectedDepartureDate.toDate():undefined, 
                                key: 'selection',
                                color: 'green'
                            }]}
                            focusedRange={[0, foc]}
                        />
                    </Pane>
                    <Pane ratio="100" row={true}>
                        <Pane ratio="50" style={{alignItems: 'flex-end'}}>
                            { offer &&
                                <>
                                <Text content={t('page.marketplace.content.nft.host')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.hostel-category')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.offer-category')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.total-price')} size="md" theme="dark" />
                                </>
                            }
                        </Pane>
                        <Pane ratio="50">
                            { offer &&
                                <>
                                <Text content={offer.societyName} size="md" theme="dark" />
                                <Text content={t(`page.marketplace.content.hostel-type.${offer.hostelCategory}`)} size="md" theme="dark" />
                                <Text content={t(`page.marketplace.content.offer-type.${offer.offerCategory}`)} size="md" theme="dark" />
                                <Text content={`${formatAmount(offer.nightUnitPrice)} JKOx(${numberDays}) = ${formatAmount(offer.nightUnitPrice.mul(new BigNumber(numberDays)))} JKO`.toString()} size="md" theme="dark" />
                                </>
                            }
                        </Pane>
                    </Pane>
                </>    
            }
        </>
    );
};

const SummaryScreen = (props : { offer? : Offer, selectedArrivalDate? : Dayjs, selectedDepartureDate? : Dayjs  }) : JSX.Element => {
    const { offer, selectedArrivalDate, selectedDepartureDate } = props;

    const {t} = useTranslation('common');
    console.log(props);

    let numberDays=0;
    if(selectedArrivalDate && selectedDepartureDate){
        numberDays = selectedDepartureDate.diff(selectedArrivalDate, 'days');
    }

    return (
        <>
            { largeScreen() ?
                <>
                    <Pane row={true} ratio="100">
                        <Text content={t('page.marketplace.content.nft.bill-or-cancel')} size="md" bold={true} theme="dark" style={{marginBottom: '10vh'}}/>
                    </Pane>
                    <Pane ratio="100" row={true}>
                        <Pane ratio="50">
                            { offer &&
                                <>
                                <Text content={t('page.marketplace.content.nft.host')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.description')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.hostel-category')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.offer-category')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.total-price')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.period-from')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.period-to')} size="md" theme="dark" />
                                </>
                            }
                        </Pane>
                        <Pane ratio="50" style={{alignItems: 'flex-start'}}>
                            { offer &&
                                <>
                                <Text content={offer.societyName} size="md" theme="dark" />
                                <Text content={offer.description} size="md" theme="dark" />
                                <Text content={t(`page.marketplace.content.hostel-type.${offer.hostelCategory}`)} size="md" theme="dark" />
                                <Text content={t(`page.marketplace.content.offer-type.${offer.offerCategory}`)} size="md" theme="dark" />
                                <Text content={`${formatAmount(computePrice(offer, numberDays))} JKO`.toString()} size="md" theme="dark" />
                                <Text content={`${timestampToDatetime(offer.periodTimestampStart.mul(DECIMALS_3).toNumber(),'DD-MM-YYYY')}`} size="md" theme="dark" />
                                <Text content={`${timestampToDatetime(offer.periodTimestampEnd.mul(DECIMALS_3).toNumber(),'DD-MM-YYYY')}`} size="md" theme="dark" />
                                </>
                            }
                        </Pane>
                    </Pane>
                </>   
                    :
                <>
                    <Pane row={true} ratio="100">
                        <Text content={t('page.marketplace.content.nft.bill-or-cancel')} size="md" bold={true} theme="dark" style={{marginBottom: '10vh'}}/>
                    </Pane>
                    <Pane ratio="100" row={true}>
                        <Pane ratio="50" style={{alignItems: 'flex-end'}}>
                            { offer &&
                                <>
                                <Text content={t('page.marketplace.content.nft.host')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.hostel-category')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.offer-category')} size="md" theme="dark" />
                                <Text content={t('page.marketplace.content.nft.total-price')} size="md" theme="dark" />
                                </>
                            }
                        </Pane>
                        <Pane ratio="50">
                            { offer &&
                                <>
                                <Text content={offer.societyName} size="md" theme="dark" />
                                <Text content={t(`page.marketplace.content.hostel-type.${offer.hostelCategory}`)} size="md" theme="dark" />
                                <Text content={t(`page.marketplace.content.offer-type.${offer.offerCategory}`)} size="md" theme="dark" />
                                <Text content={`${formatAmount(offer.nightUnitPrice)} JKOx(${numberDays}) = ${formatAmount(offer.nightUnitPrice.mul(new BigNumber(numberDays)))} JKO`.toString()} size="md" theme="dark" />
                                </>
                            }
                        </Pane>
                    </Pane>
                </>    
            }
        </>
    );
};

const WaitScreen = (props : {  offer: Offer }) : JSX.Element => {
    const { offer } = props;

    const {t} = useTranslation('common');
    
    return (
        <Pane ratio="100">
            <Text content={offer.hostelName} size="sm" theme="dark" bold={true} style={{ marginTop:'2vh', marginBottom:'3vh'}}/>
            <Pane ratio="100" row={true}>
                <Text content={t('page.marketplace.content.nft.wait')} size="sm" bold={true} theme="dark" />
            </Pane>
        </Pane>
    );
};

const TransactionScreen = (props : {  offer: Offer, ended: boolean, succeded: boolean, errorMsg: string, txHash: string }) : JSX.Element => {
    const { offer, ended, succeded, errorMsg, txHash } = props;
    
    return (
        <div style={{width : '100%', minWidth: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin : 0, padding : 0}}>
            <Text content={offer.hostelName} size="sm" theme="dark" bold={true} style={{ marginTop:'2vh', marginBottom:'3vh'}}/>
            <div className="parts-global-md" >
                {ended ?
                    <div className="parts-content-transaction-group-xl">
                        <Text content={succeded ? 'Transaction succeded' : (errorMsg ? errorMsg : 'Transaction failed')} size="sm" theme="dark" />
                        {(txHash && txHash.length>0) &&
                            <>
                            <Text content={'View on etherscan: '} size="sm" theme="dark" />
                            <a href={getEtherscanLink(txHash)}>{formatAddress(txHash,16)}</a>
                            </>
                        }
                    </div>
                    :
                    <div className="parts-content-transaction-group-xl">
                        <Text content={'Transaction processing ...'} size="sm" theme="dark" />
                    </div>
                }
            </div>
        </div>
    );
};

interface BookingViewState{
    account: string;
    accountName: string;

    selectedArrivalDate?: Dayjs; 
    selectedDepartureDate?: Dayjs;
    foc: 0 | 1;
    disabledDates : number[];
    bookings : Booking[];

    action: string;

    ended: boolean;
    succeded: boolean;
    errorMsg: string;
    txHash: string;

    activeChild: number;
};

export const BookingView = (props : {offer? : Offer, arrivalDate?: Dayjs, departureDate?: Dayjs, cancel: () => void}) : JSX.Element => {
    const { offer, arrivalDate, departureDate, cancel} = props;

    const {t} = useTranslation('common');

    const [bookingViewState, setBookingViewState] = useState<BookingViewState>({
      account: '',
      accountName: '',
      selectedArrivalDate : arrivalDate,
      selectedDepartureDate : departureDate,
      foc: 0,
      disabledDates: [],
      bookings : [],

      action : EMPTY,
    
      ended: false,
      succeded: false,
      errorMsg: EMPTY,
      txHash: EMPTY,

      activeChild: START_STATE
    } as BookingViewState);

    const { selectedArrivalDate, selectedDepartureDate, foc, disabledDates, bookings, action, ended, succeded, errorMsg, txHash, activeChild } = bookingViewState;

    useEffect(() => {
        load(activeChild, action, arrivalDate, departureDate);
        subscribeConnectEvent((accounts : string[]) : void => {
            load(activeChild, action, arrivalDate, departureDate);
        });
    }, [JSON.stringify(offer),arrivalDate,departureDate]); 

    const reload = () => {
        setTimeout(() => {
            console.log('---------------reload()');
            load(START_STATE, EMPTY, arrivalDate, departureDate);
        },4000);
    };

    const handleAction = async (action : string) : Promise<void> => {
        console.log('==== action ',action);
        setTimeout(() => {
            console.log('---------------reload()');
            load(WAIT_STATE, action, arrivalDate, departureDate);
        },4000);
        setBookingViewState({ ...bookingViewState, activeChild : WAIT_STATE, action });
    };

    const handleBook = async () : Promise<void> => {
        const { account, selectedArrivalDate, selectedDepartureDate } = bookingViewState;
        try{
            console.log(bookingViewState);
            if(!account || !offer || !selectedArrivalDate || !selectedDepartureDate){
                return;
            }
            let numberDays=0;
            if(selectedArrivalDate && selectedDepartureDate){
                numberDays = selectedDepartureDate.diff(selectedArrivalDate, 'days');
            }
            
            const price = computePrice(offer, numberDays);

            const arrivalTimestamp = new BigNumber(dateToMiddaySecondTimestamp(selectedArrivalDate.toDate()));
            const departureTimestamp = new BigNumber(dateToMiddaySecondTimestamp(selectedDepartureDate.toDate()));
    
            bookOffer(account, offer.offerId, price, arrivalTimestamp, departureTimestamp, 'half pension');
        }
        catch(e : any){
            console.error(e);
        }
    };

    const handleNftBill = async () : Promise<void> => {
        const { account } = bookingViewState;
        try{
            console.log('------------NFT Bill-----------');
            const bookingId : BigNumber = bookings.length>0 ? bookings[0].bookingId : ZERO;
            mintBill(account, bookingId,
                async (txHash: string) => {
                    reload();
                    console.log('Bill minted: '+txHash);
                    setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: true, txHash});
                },
                async (txHash: string, errorMsg : string) => {
                    console.error('Error while minting Bill ',errorMsg);
                    reload();
                    setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: false, txHash, errorMsg});
                }
            );
        }
        catch(e : any){
            console.error(e);
        }
    };

    const handleCancel = async () : Promise<void> => {
        const { account } = bookingViewState;
        try{
            console.log('------------Cancel-----------');
            const bookingId : BigNumber = bookings.length>0 ? bookings[0].bookingId : ZERO;
            cancelAReservation(account, bookingId,
                async (txHash: string) => {
                    reload();
                    console.log('Reservation Canceled: '+txHash);
                    setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: true, txHash});
                },
                async (txHash: string, errorMsg : string) => {
                    console.error('Error while canceling Reservation ',errorMsg);
                    reload();
                    setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: false, txHash, errorMsg});
                }
            );
        }
        catch(e : any){
            console.error(e);
        }
    };

    const bookOffer = async (account : string, offerId : BigNumber, price: BigNumber, arrivalTimestamp: BigNumber, departureTimestamp: BigNumber, reservationPackage: string) : Promise<void> => {
        approveBookingContract(account, price,
            async (txAllowHash: string) =>{
                // deposit NFT
                console.log('bookAReservation: '+offerId);
                bookAReservation(account, newIdAsNumber(), offerId, arrivalTimestamp, departureTimestamp, reservationPackage,
                    async (txHash: string) => {
                        reload();
                        console.log('Reservation booked: '+txHash);
                        setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: true, txHash});
                    },
                    async (txHash: string, errorMsg : string) => {
                        console.error('Error while booking offer ',errorMsg);
                        reload();
                        setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: false, txHash, errorMsg});
                    }
                );
            },
            async (txAllowHash: string, errorMsg : string) => {
                console.error('JKO allowance failed for booking contract.',errorMsg);
                reload();
                setBookingViewState({...bookingViewState, activeChild: END_STATE, action : EMPTY, ended: true, succeded: false, txHash: txAllowHash, errorMsg});
            }
        );

    };

    const handleDateSelection = (range: RangeKeyDict) : void => {
        const { startDate, endDate, key } = range.selection;
        console.log(range.selection);
        if(startDate && endDate){
            setBookingViewState({...bookingViewState, selectedArrivalDate: dayjs(dateToDatetime(startDate,'MM-DD-YYYY')), selectedDepartureDate: dayjs(dateToDatetime(endDate,'MM-DD-YYYY')), foc: (foc==0 ? 1:0) });
        }
    };

    const load = async (activeChild: number, action: string, arrivalDate?: Dayjs, departureDate?: Dayjs) : Promise<void> => {
        console.log('--------load()--------');
        let newBookingViewState : BookingViewState = {...bookingViewState, selectedArrivalDate: arrivalDate, selectedDepartureDate: departureDate, foc: (foc==0 ? 1:0) };
        try{
            if(activeChild == START_STATE){
                let account : string = connectedAccount();
                console.log(account);
                if(account.length!==0){
                    newBookingViewState = {...newBookingViewState, account};
                    const accountId : ID  = await getId(account);
                    if(accountId){
                        newBookingViewState = {...newBookingViewState, accountName: accountId.id};
                    }
                }
                console.log(offer && arrivalDate && departureDate);
                if(offer && arrivalDate && departureDate){
                    const arrivalTimestamp = new BigNumber(dateToMiddaySecondTimestamp(arrivalDate.subtract(1,'month').toDate()));
                    const departureTimestamp = new BigNumber(dateToMiddaySecondTimestamp(departureDate.add(1,'month').toDate()));
                    console.log("||==============================> loadPeriodOffers()");
                    const periodOffers : Offer[] = await loadPeriodOffers(200, 0, offer.offerId, arrivalTimestamp, departureTimestamp);
                    console.log(account);
                    console.log("||==============================> loadBookings()");
                    const bookings : Booking[] = await loadBookings(200, 0, offer.offerId, account);
                    console.log(bookings);
                    const disabledDates : number[] = periodOffers.filter(o => o.available.toNumber() == 0).map(o => o.timestamp.toNumber());
                    newBookingViewState = {...newBookingViewState, disabledDates, bookings};
                }
                setBookingViewState(newBookingViewState);
            }
            if(activeChild == WAIT_STATE){
                console.log('----> WAIT_STATE');
                clearCaches();
                if(action == BOOK_ACTION){
                    handleBook();
                }
                else if(action == BILL_ACTION){
                    handleNftBill();
                }
                else if(action == CANCEL_ACTION){
                    handleCancel();
                }
            }
        }
        catch(e : any){
            console.error('Error while retrieving available date for offer ',e);
        }
    };
    
    console.log('=== bookings ?',bookings.length);
    return(
        <>
            {(offer) ?
                <Overlay zIndex={1} >
                    <div style={{width : '100%', minWidth: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin : 0, padding : 0}}>
                        <div className="booking-view-container">
                            <div className="booking-view-header">
                                <CircleButton icon="fa-solid fa-x" style={{marginTop: '1.5vh', marginRight: '1.5vh'}} onClick={cancel}/>
                            </div>
                            <div className="booking-view-body">
                                {bookings.length==0? 
                                    <CardLayout activeChild={activeChild} >
                                        <BookingScreen offer={offer} selectedArrivalDate={selectedArrivalDate} selectedDepartureDate={selectedDepartureDate} disabledDates={disabledDates} foc={foc} handleDateSelection={handleDateSelection} />
                                        <WaitScreen offer={offer} />
                                        <TransactionScreen offer={offer} ended={ended} succeded={succeded} errorMsg={errorMsg} txHash={txHash} />
                                    </CardLayout>
                                    :
                                    <CardLayout activeChild={activeChild} >
                                        <SummaryScreen offer={offer} selectedArrivalDate={selectedArrivalDate} selectedDepartureDate={selectedDepartureDate} />
                                        <WaitScreen offer={offer} />
                                        <TransactionScreen offer={offer} ended={ended} succeded={succeded} errorMsg={errorMsg} txHash={txHash} />
                                    </CardLayout>
                                }
                            </div>
                            <div className="booking-view-footer">
                                { (bookings.length==0 && activeChild==START_STATE) &&
                                    <RoundButton title={t('page.marketplace.content.nft.book')} reverse={false} onClick = {() => handleAction(BOOK_ACTION)}/>
                                }
                                { (bookings.length!=0 && activeChild==START_STATE) &&
                                    <RoundButton title={t('page.marketplace.content.nft.print')} reverse={false} onClick = {() => handleAction(BILL_ACTION)}/>
                                }
                                { (bookings.length!=0 && activeChild==START_STATE) &&
                                    <RoundButton title={t('page.marketplace.content.nft.cancel')} reverse={true} onClick = {() => handleAction(CANCEL_ACTION)}/>
                                }
                            </div>
                        </div>
                    </div>
                </Overlay>
                :
                <>
                </>
            }
        </>
    );

};
