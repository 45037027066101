import React, { useEffect, useRef }  from "react";
import { EMPTY } from "../../constant/constant";

export const Input = (props : { value: any, type: string, onClick?: (event : any) => void, onChange?: (event : any) => void, style? : any, className? : string}) : JSX.Element => {
    const {value, type, onClick, onChange, style, className}  = props;

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if(inputRef.current && onChange){
            inputRef.current.focus();
        }
    }, []);

    const change = (event : any) => {
        if(onChange && value != event.target.value){
            onChange(event);
        }
    };

    const cl = className ? className : EMPTY ;
    const st = style ? style : {};

    return ( 
        <input value={value} type={type} onClick={onClick} onChange={change} style={st} className={cl} ref={inputRef}/>
    );
}
