import React from "react";

export const Overlay = (props: { children : any , zIndex : number, style? : any }) : JSX.Element => {
    const { children, zIndex, style } = props;
    const st = style ? style : {};

    return (
        <div style={{ minWidth: '100vw', width : '100vw', backgroundColor: 'transparent', margin : '0px', padding : '0px', position : 'absolute', top : '0px', left : '0px', ...st}}>
            {React.Children.map(children || null, (child, i) => {
                return (
                    <child.type {...child.props} key={`overlay-child-key-${i}`} zIndex={zIndex ? zIndex : 1} />
                );
            })}
        </div>
    );
};