import BigNumber from 'bn.js'

export const EMPTY : string = '';

export type MD = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

export const ZERO = new BigNumber(0);

// 1 Jul 2024 12H00 00:00  
export const SUMMER_START_TIMESTAMP = new BigNumber(1719835200);
export const SUMMER_START_DATE = '2024-07-01';

export const DECIMALS_18 = new BigNumber(10).pow(new BigNumber(18));
export const DECIMALS_15 = new BigNumber(10).pow(new BigNumber(15));
export const DECIMALS_3 = new BigNumber(10).pow(new BigNumber(3));
export const DECIMALS_2 = new BigNumber(10).pow(new BigNumber(2));

export const ASSET_TYPE_COIN = 'coin';

export const GOLD_COIN = 'gold';

export const SILVER_COIN = 'silver';

export const GOLD_COIN_SRC  = 'coin/1-oz-maple-leaf-gold-coin.png';

export const SILVER_COIN_SRC = 'coin/1-oz-silver-maple-leaf-coin.png';

export const JURAKKO_WALLET = 'JURAKKO-WALLET';

export const NUMBER_PATTERN_STR = '[0-9]+';
export const NUMBER_PATTERN = new RegExp('[0-9]+','i');

export const CRYPTO_ADDRESS_PATTERN_STR = '0x[A-Za-z0-9=+/]+';
export const CRYPTO_ADDRESS_PATTERN = new RegExp(CRYPTO_ADDRESS_PATTERN_STR,'i');

export const NAME_PATTERN_STR = '([a-zA-Z._-]|\s)+';
export const NAME_PATTERN = new RegExp(NAME_PATTERN_STR,'i');

export const DEBOUNCE_TIME = 10;
