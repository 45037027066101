import zIndex from "@mui/material/styles/zIndex";
import React, { Fragment } from "react";


export const CardLayout = (props : { activeChild: number, children : any , style? : any}) : JSX.Element => {
    const { activeChild, children, style } = props;
    const st = style ? style : {};
    return (
        <div style={{ minWidth: '100%', width : '100%', backgroundColor: 'transparent', margin : '0px', padding : '0px', ...st}}>
            {React.Children.map(children || null, (child, i) => {
                return (
                    <Fragment key={`stack-component-key-${i}`.toString()}>
                    {i === activeChild ?
                        <div  style={{ minWidth: '100%', width : '100%', margin : '0px', padding : '0px', backgroundColor: 'transparent' }}>
                            <child.type {...child.props} key={`stack-item-key-${i}`.toString()}  />
                        </div>
                        :
                        <></>
                    }
                    </Fragment>
                );
            })}
        </div>
    );
}