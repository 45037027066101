import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Front from './view/Front';

//import './index.css';

//https://mdbootstrap.com/support/react/react-multiselect-not-working-properly/
//https://mdbootstrap.com/support/react/style-sheet-not-working/


//import '@fortawesome/fontawesome-free/css/all.min.css'; 
//import 'bootstrap-css-only/css/bootstrap.min.css'; 
//import 'mdbreact/dist/css/mdb.css';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

/**
 * @author Christophe Convert
 * 
 */
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      fr: {
          common: common_fr
      },
  },                             
});

const App = () => (
  <I18nextProvider i18n={i18next}>
    <Suspense fallback="loading">
      <Front />
    </Suspense>
  </I18nextProvider>
);

export default App;
