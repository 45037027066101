
/**
 * @author Christophe Convert
 * 
 */
export interface ProviderConfig {
    chainId : number;
    infuraId : string;
    wsURL : string,
    providerURL : string;
};

const INFURA_ID = process.env.REACT_APP_INFURA_ID ? process.env.REACT_APP_INFURA_ID : '';

export const ETH_NETWORK = new Map<number,ProviderConfig>([
    [1, { chainId : 1, infuraId: INFURA_ID, wsURL : `wss://mainnet.infura.io/ws/v3/${INFURA_ID}`.toString(), providerURL : `https://mainnet.infura.io/v3/${INFURA_ID}`.toString()}],
    [11155111, { chainId : 11155111, infuraId: INFURA_ID, wsURL : `wss://sepolia.infura.io/ws/v3/${INFURA_ID}`.toString(), providerURL : `https://sepolia.infura.io/v3/${INFURA_ID}`.toString()}],
    [80001, { chainId : 80001, infuraId: INFURA_ID, wsURL : `wss://polygon-mumbai.infura.io/ws/v3/${INFURA_ID}`.toString(), providerURL : `https://polygon-mumbai.infura.io/v3/${INFURA_ID}`.toString()}]
]);
