import React , { useEffect, useRef, useState }  from "react";

import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import {fromLonLat, toLonLat} from 'ol/proj';
import { distance, MarkerParams, markers, middle, ZOOM_LEVEL_1} from "../../../utils/GeoUtils";

import { Coordinate } from "ol/coordinate";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";

import { getBottomLeft, getTopRight } from "ol/extent";
import { localUrl } from "../../img/Img";
import { newId } from "../../../utils/IdUtils";
import 'ol/ol.css';
import './SimpleViewMap.css';


export interface MapData {
  location? : Coordinate;
  title? : string;
  imgPath? : string;
}


interface DynamicMapState {
  loaded : boolean;
  loadSucceded : boolean;
}


export const toProjection = (p : number[]) : number[] => fromLonLat([p[0]*1e-6, p[1]*1e-6]);

export const toProjections = (border : number[][]) : number[][] => border.map(p => toProjection(p));

const wrapLon = (value: number) : number => {
  const worlds = Math.floor((value + 180) / 360);
  return value - worlds * 360;
};

const agregator = (data : any , bottomLeft : Coordinate, topRight : Coordinate, zoom : number) : Promise<any> =>  {
  return Promise.resolve(data);
};

const renderer = (data : any, bottomLeft : Coordinate, topRight : Coordinate, zoom : number) : VectorLayer<VectorSource<Geometry>>[] => {
    const layers : VectorLayer<VectorSource<Geometry>>[] = [];

    const diag = 1e6*distance(bottomLeft, topRight);

    const markerParams : MarkerParams = { 
        markerCenter : [], 
        markerIcons : [], 
        markerIds : [], 
        markerTypes : [], 
        markerTexts : []
    };

    const { markerCenter, markerIcons, markerIds, markerTypes, markerTexts } = markerParams;
    

    const { location, title, imgPath } = data as MapData;

    if(location && title && imgPath){
      markerCenter.push(toProjection(location));
      markerIcons.push(localUrl(imgPath));
      markerIds.push(title);
      markerTypes?.push('location');
      markerTexts?.push(title);
    }

    const mrkrs = markers(markerParams, zoom);
    layers.push(mrkrs);

    return layers;
};

const SimpleViewMap = (props: { zoom : number, mapCenter?: Coordinate, loader? : (bottomLeft : Coordinate, topRight : Coordinate, zoom : number) => Promise<any>}) : JSX.Element => {
    const { zoom, mapCenter, loader } = props;
    const [dynamicMapState , setDynamicMapState] = useState<DynamicMapState>({} as DynamicMapState);

    const mapRef = useRef<Map | null>(null);

    const mapId = `map-${newId()}`.toString();

    const buildMap = (centerMap? : Coordinate) : Map => {
      const source = new VectorSource({wrapX: false});
    
      let map : Map;
      // Clean Map if it already exists.
      if(mapRef.current){
        map = mapRef.current;
        const vectorLayersToRemove : VectorLayer<VectorSource<Geometry>>[] = [];
        map.getLayerGroup().getLayers().forEach(layer => {
          if(layer instanceof VectorLayer){
            vectorLayersToRemove.push(layer as VectorLayer<VectorSource<Geometry>>); ;
          }
        });
        vectorLayersToRemove.forEach(layer => map.removeLayer(layer));
        map.addLayer(
          new VectorLayer({
            source: source,
          })
        );
      }
      else{
        // Create Map if it do not exists.
        map = new Map({
          layers: [
            new TileLayer({
              source: new OSM(),
            }),
            new VectorLayer({
              source: source,
            })
          ],
          target: mapId,
          view: new View({
            center : centerMap,
            zoom,
          }),
        });

        const moveHandler = (e : any) => {
          //const map = e.map;
          const extent = map.getView().calculateExtent();

          const bLeft = toLonLat(getBottomLeft(extent));
          const tRight = toLonLat(getTopRight(extent));

          const bottomLeft = [wrapLon(bLeft[0]),bLeft[1]];
          const topRight = [wrapLon(tRight[0]),tRight[1]];
          
          load(bottomLeft, topRight, undefined);
        };

        map.on('moveend', moveHandler);

      }
      return map;
    };

    const load = async (bottomLeft? : Coordinate, topRight? : Coordinate, center? : Coordinate) : Promise<void> => {
      try{
        const centerMap = (bottomLeft && topRight) ? middle(bottomLeft, topRight) : center;

        let map = buildMap(centerMap);

        let zoom = map.getView().getZoom() || ZOOM_LEVEL_1;

        let data : any[] = [];
        if(loader && bottomLeft && topRight){
          data = await loader(bottomLeft, topRight, zoom);
        }
        if( bottomLeft && topRight){
          data = await agregator(data, bottomLeft, topRight, zoom);
        }
    
        let vectorLayersToRender : VectorLayer<VectorSource<Geometry>>[] = [];
        if( bottomLeft && topRight){
          vectorLayersToRender = renderer(data, bottomLeft, topRight, zoom);
        }

        // Push loaded geometries
        vectorLayersToRender.forEach(vectorLayer => map.addLayer(vectorLayer));

        mapRef.current=map;
        setDynamicMapState({  ...dynamicMapState, loaded: true, loadSucceded: true });
      }
      catch(e :any){
        console.log(e);
        setDynamicMapState({  ...dynamicMapState, loaded: true, loadSucceded: false });
      }
    };

    useEffect(() => {
      if(mapCenter){
        load(undefined, undefined, toProjection(mapCenter));
      }
      else{
        navigator.geolocation.getCurrentPosition((pos : GeolocationPosition) =>{
          const crd = pos.coords;
          const center = fromLonLat([crd.longitude, crd.latitude]);
          load(undefined, undefined, center);
        });
      }
  }, []);

  const getHeight = () : string => {
    if(window.screen.availWidth <= 819){
      console.log('54vw');
      return '54vw';
    }
    else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      console.log('24.5vw');
      return '24.5vw';
    }
    else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      console.log('13.96vw');
      return '13.96vw';
    }
    else{
      console.log(window.screen.availWidth);
      console.log('8.75vw');
      return '8.75vw';
    }
  };

  const height = getHeight();
  
  return (
    <div style={{ minWidth : '100%', maxWidth : '100%', margin: '0px', padding: '0px' }}>
      <div id={mapId} style={{ width : '100%', minWidth : '100%', height : height, minHeight : height, margin: '0px', padding: '0px' }} />
    </div>
  );
}
    
export default SimpleViewMap;
