import { v4 as uuidv4 } from "uuid";
import BigNumber from 'bn.js';

// Web3.js 4.x import
const { Web3 } = require('web3');

/**
 * @author Christophe Convert
 * 
 */
export const newId = () : string => {
    return uuidv4();
};

export const newIdAsNumber = () : BigNumber => {
  return new BigNumber(Web3.utils.randomHex(16).replace('0x',''),16);
};
