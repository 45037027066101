import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connectedAccount, subscribeConnectEvent } from "../../../service/crypto/Crypto";
import { formatAddress, formatAmount, getId, ID, iPart } from "../../../service/asset/Asset";
import { DECIMALS_18, DECIMALS_3, EMPTY, ZERO } from "../../../constant/constant";
import { Text } from "../../../component/text/Text";
import { Scroll } from "../../../component/scroll/Scroll";
import { CardLayout } from "../../../component/layout/card-layout/CardLayout";
import { RoundButton } from "../../../component/button/round/RoundButton";
import { Connect } from "../../../component/connect/Connect";
import { Offer } from "../../../service/hostel/Hostel";
import { Dayjs } from "dayjs";
import { timestampToDatetime } from "../../../utils/DateUtils";
import './BookingSummary.css';

export interface BookState{
    activeChild: number;
    account : string;
    accountName : string;

    canBook: boolean;

    ended : boolean;
    succeded : boolean;
    txHash : string;
    errorMsg : string;
};

/**
 * @author Christophe Convert
 * 
 */
export const BookingSummary = (props : {offer : Offer, arrivalDate: Dayjs, departureDate: Dayjs, onOfferSelected : (offer : Offer) => void }) : JSX.Element => {
    const { offer, arrivalDate, departureDate, onOfferSelected } = props;

    const [bookState, setBookState] = useState<BookState>({
        activeChild: 0,
        account: '',
        accountName: '',

        canBook: false,

        ended : false,
        succeded : false,
        txHash : '',
        errorMsg : ''
    } as BookState);

    const {t} = useTranslation('common');

    useEffect(() => {
        subscribeConnectEvent((accounts : string[]) : void => {
            loadIds();
        });
        loadIds();
    },[]);

    const { activeChild, account, accountName, ended, succeded, txHash, errorMsg } = bookState;

    const loadIds = async () => {
        let newBookState = {...bookState, account};
        try{
            const account = connectedAccount();
            if(account.length!==0){
                newBookState = { ...newBookState, account};
                const accountId : ID  = await getId(account);
                if(accountId){
                    newBookState = {...newBookState, accountName: accountId.id};
                }
            }
        }
        catch(e : any){
            console.log(e);
        }


        console.log(newBookState);
        setBookState({...bookState, ...newBookState});
    };

    const getScrollHeight = () : string => {
        if(window.screen.width <= 819){
          return '36vw';
        }
        else if (window.screen.width >= 820 && window.screen.width <= 1019){
          return '16vw';
        }
        else if (window.screen.width >= 1020 && window.screen.width <= 1919){
          return '9vw';
        }
        else{
          return '5vw';
        }
    };

    const largeScreen = () : boolean => {
        return window.screen.width >= 1020;
    };

    const up = t('page.marketplace.cart.items.up');
    const down = t('page.marketplace.cart.items.down');

    const back = async () => {
        if(activeChild>0){
            setBookState({...bookState, activeChild: activeChild-1});
        }
    };

    const summaryScreen = () : JSX.Element => {
        return (
            <div style={{width : '100%', margin : 0, padding : 0}}>
                <div className="book-global-md">
                    <div className="book-content-transaction-group">
                        <Text content={t('page.marketplace.content.nft.host')} size="sm" theme="dark" />
                        <Text content={t('page.marketplace.content.nft.hostel-category')} size="sm" theme="dark" />
                        <Text content={t('page.marketplace.content.nft.offer-category')} size="sm" theme="dark" />
                        <Text content={t('page.marketplace.content.nft.night-price')} size="sm" theme="dark" />
                        <Text content={t('page.marketplace.content.nft.available')} size="sm" theme="dark" />
                        <Text content={t('page.marketplace.content.nft.period-from')} size="sm" theme="dark" />
                        <Text content={t('page.marketplace.content.nft.period-to')} size="sm" theme="dark" />
                    </div>
                    <div className="book-content-transaction-group">
                        <Text content={offer.societyName} size="sm" theme="dark" />
                        <Text content={t(`page.marketplace.content.hostel-type.${offer.hostelCategory}`)} size="sm" theme="dark" />
                        <Text content={t(`page.marketplace.content.offer-type.${offer.offerCategory}`)} size="sm" theme="dark" />
                        <Text content={`${formatAmount(offer.nightUnitPrice)} JKO`.toString()} size="sm" theme="dark" />
                        <Text content={`${offer.available} / ${offer.quantity}`} size="sm" theme="dark" />
                        <Text content={`${timestampToDatetime(offer.periodTimestampStart.mul(DECIMALS_3).toNumber(),'DD-MM-YYYY')}`} size="sm" theme="dark" />
                        <Text content={`${timestampToDatetime(offer.periodTimestampEnd.mul(DECIMALS_3).toNumber(),'DD-MM-YYYY')}`} size="sm" theme="dark" />
                    </div>
                </div>
                <Scroll maxHeight={getScrollHeight()}>
                </Scroll>
            </div>
        );
    };

    const checkScreen = () : JSX.Element => {
        return (
            <div style={{width : '100%', minWidth: '100%', margin : 0, padding : 0}}>
                
            </div>
        );
    };


    const transactionScreen = () : JSX.Element => {
        return (
            <div style={{width : '100%', minWidth: '100%', margin : 0, padding : 0}}>
                
            </div>
        );
    };

    return (
        <>
            <div className="nft-description-md">
            { offer ?
                <CardLayout activeChild={activeChild}>
                    <>
                        {summaryScreen()}
                    </>
                    <>
                        {checkScreen()}
                    </>
                    <>
                        {transactionScreen()}
                    </>
                </CardLayout>
                :
                <>
                </>
            }
            </div>
            <div className="nft-bottom-md">
                {(account.length ==0) ?
                    <Connect />
                    :
                    <RoundButton title={t('page.marketplace.content.nft.book')}  onClick = {() => onOfferSelected(offer)} /> 
                }
            </div>
        </>
    );
};
