import React, { useRef, useState } from "react";
import { newId } from "../../utils/IdUtils";
import './TabPanel.css';

export interface TabPanelItem {
    title : string;
    icon? : string;
}

export interface TabPanelState {
    activeLink: number;
}

const TabPanelHeader = (props: { item: TabPanelItem, i : number, onClick : () => void }) : JSX.Element => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const {item, i, onClick} = props;

    const resetTabPanelHeadersStyle = () => {
        const ancestor = divRef.current?.parentElement?.parentElement;
        if(divRef.current && ancestor){
            const headerTabs = ancestor.getElementsByClassName("tab-panel-header-item");
            for(let k=0;k<headerTabs.length;k++){
                headerTabs[k].className=headerTabs[k].className.replace('active','');
            }
        }
    };

    const onClickWithStyle = () => {
        resetTabPanelHeadersStyle();
        if(divRef.current&&!divRef.current.className.includes('active')){
            divRef.current.className=divRef.current.className+' active';
        }
        onClick();
    };

    return (
        <div className="tab-panel-header-item-wrapper" key={'tab-panel-header-item-wrapper-'+i} >
            <div className="tab-panel-header-item"  key={'tab-panel-header-item-'+i} onClick={onClickWithStyle} ref={divRef}>
                { (item.icon) && <i className={item.icon} style = {{fontSize: '1.2em'}}/> }
                <div style={{marginLeft : '1vw'}}>{item.title}</div>
            </div>
        </div>
    );
};

export const TabPanel = (props: { items: TabPanelItem[], activeLink : number, children : any }) : JSX.Element => {
    const [tabPanelState, setTabPanelState] = useState<TabPanelState>({ activeLink : props.activeLink } as TabPanelState);

    let { activeLink } = tabPanelState;
    return (
        <div className="tab-panel-container">
                <div className="tab-panel-header">
                    { props.items.map((item, i) => (   
                        <TabPanelHeader item={item} i={i} key={`tab-panel-key-header-${i}-${newId()}`.toString()} onClick={() => setTabPanelState({ activeLink : i})} />
                    ))}
                </div>
            <hr className="tab-panel-header-separation" />
            <div className="tab-panel-body">
                {React.Children.map(props.children || null, (child, i) => {
                    return (<>{ (i === activeLink) ? <child.type {...child.props} key={`tab-panel-key-${i}-${newId()}`.toString()}  /> : (<></>) } </>);
                })}
            </div>
        </div>
    );
};

