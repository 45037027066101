import React, { useEffect, useRef, useState } from "react";
import { formatAddress } from "../../service/asset/Asset";
import { connectAndNotify, connectedAccount, disconnectAndNotify, subscribeConnectEvent } from "../../service/crypto/Crypto";
import { RoundButton } from "../button/round/RoundButton";

export interface ConnectState{
    account : string;
};

export const Connect = () : JSX.Element => {

  const [connectState, setConnectState] = useState<ConnectState>({
      account : connectedAccount()
  } as ConnectState);

  useEffect(() => {
    subscribeConnectEvent((accounts : string[]) : void =>{
      console.log('---------------ON CONNECT---------------',accounts);
      setConnectState({...connectState, account: connectedAccount()});
    });
    setConnectState({...connectState, account: connectedAccount()});
  }, []); 

  const getWidth = () : string => {
    if(window.screen.availWidth <= 819){
      return '20vw';
    }
    else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return '12vw';
    }
    else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      return '9vw';
    }
    else{
      return '7vw';
    }
  };

  const { account } = connectState;

  return(
      <>
      {(account.length > 0) ?
          <RoundButton title={formatAddress(account,16)} icon="fas fa-solid fa-wallet" style={{width : getWidth(), minWidth : getWidth()}} onClick={() => {
              console.log("disconnect");
              disconnectAndNotify()
              .catch((error : any) => { console.log(error)});
          }} />
          :
          <RoundButton title="Connect Wallet"  style={{width : getWidth(), minWidth : getWidth()}} onClick={() => {
              connectAndNotify()
              .catch((error : any) => { console.log(error)});
          }} />
      }
      </>
  );
}