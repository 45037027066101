import { Feature, Map, Overlay } from "ol";
import { Coordinate, toStringHDMS } from "ol/coordinate";
import { getForViewAndSize } from "ol/extent";
import { FeatureLike } from "ol/Feature";
import Circle from "ol/geom/Circle";
import Geometry from "ol/geom/Geometry";
import MultiPoint from "ol/geom/MultiPoint";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import { SketchCoordType } from "ol/interaction/Draw";
import Interaction from "ol/interaction/Interaction";
import PointerInteraction from "ol/interaction/Pointer";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, toLonLat } from "ol/proj";
import { State } from "ol/render";
import VectorSource from "ol/source/Vector";
import { Fill, Icon, IconImage, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";

export const isRemoteUrl = (url: string) : boolean => {
    return url.startsWith('https://') || url.startsWith('http://') ;
 };

/* ------------------------------------------------------  ZOOM ------------------------------------------------------------------- */

export const ZOOM_LEVEL_1 = 3;
export const ZOOM_LEVEL_2 = 8;
export const ZOOM_LEVEL_3 = 12;
export const ZOOM_LEVEL_4 = 16;

export const getZoom = (map : Map) : number | undefined => {
    const view = map.getView();
    return view.getZoom();
}

export const setZoom = (map : Map, zoom : number) : void => {
    const view = map.getView();
    if(zoom) {
        view.setZoom(zoom);
    }
}

export const increaseZoom = (map : Map) : void => {
    const view = map.getView();
    const zoom = view.getZoom();
    if(zoom) {
        view.setZoom(zoom + 1);
    }
}

export const zoomAndCenter = (map : Map, center : Coordinate, zoomInc? : number) : void => {
    const view = map.getView();
    const zoom = view.getZoom();
    if(zoom && zoom < 17) {
        view.setZoom(zoom + (zoomInc ? zoomInc : 1));
    }
    view.setCenter(center);
}

export const animatedZoomAndCenter = (map : Map, center : Coordinate, onFinish : (complete: any) => void) : void => {
    const duration = 2000;
    const view = map.getView();
    const zoom = view.getZoom() || ZOOM_LEVEL_1;

    let targetZoom = zoom;
    if(zoom < ZOOM_LEVEL_4){
        targetZoom = zoom+2;
    }

    let parts = 1;
    let called = false;
    const callback = (complete: any) => {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        view.setCenter(center);
        onFinish(complete);
      }
    }
    view.animate(
      {
        zoom: zoom - 1,
        center,
        duration: duration / 4,
      },
      {
        zoom: targetZoom,
        center: center,
        duration: 3 * duration / 4,
      },
      callback
    );
}

export const animatedDeepZoomAndCenter = (map : Map, center : Coordinate, onFinish : (complete: any) => void) : void => {
    const duration = 2000;
    const view = map.getView();
    const zoom = view.getZoom();

    let targetZoom = zoom;
    if(zoom < ZOOM_LEVEL_4){
        targetZoom = zoom+4;
    }

    let parts = 1;
    let called = false;
    const callback = (complete: any) => {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        view.setCenter(center);
        onFinish(complete);
      }
    }
    view.animate(
      {
        zoom: zoom ,
        center,
        duration: duration / 5,
      },
      {
        zoom: targetZoom,
        center: center,
        duration: 4 * duration / 5,
      },
      callback
    );
}

export const decreaseZoom = (map : Map) : void => {
    const view = map.getView();
    const zoom = view.getZoom();
    if(zoom) {
        view.setZoom(zoom - 1);
    }
}

/* ------------------------------------------------------  MARKER ------------------------------------------------------------------- */

export const addMarker = (map : Map, coords : Coordinate, markerId : string, iconFileName: string, text? : string) : void => {
    const zoom = getZoom(map);
    map.addLayer(markers({markerCenter : [fromLonLat(coords)], markerIcons : [iconFileName], markerIds : [markerId], markerTypes : undefined, markerTexts : text ? [text] : undefined}, zoom?zoom:0));     
}

export interface MarkerParams {
    markerCenter : Coordinate[];
    markerIcons : string[];
    markerIds : string[];
    markerTypes? : string[];
    markerTexts? : string[];
}

const scale = (src: string, zoom : number) => {
    return isRemoteUrl(src) ? 0.005*Math.pow(zoom,0.8) : 0.03;
};

export const markers = (markerParams : MarkerParams, zoom: number) : VectorLayer<VectorSource<Geometry>> => {
    const iconFeatures : Feature<Geometry>[] = [];
    const { markerCenter, markerIds, markerTypes, markerIcons, markerTexts } = markerParams;
    
    const size = markerCenter.length;
    for(let i=0; i<size; i++){
        const iconFeature = new Feature({ 
            type: 'icon', 
            geometry: new Point(markerCenter[i]) 
        });

        const textStyle = markerTexts ? 
            new Text({
                text : markerTexts[i],
                scale: 1,
                fill: new Fill({
                    color: "#fff"
                }),
                stroke: new Stroke({
                    color: "0",
                    width: 3
                })
            }) : undefined;

        
        iconFeature.setStyle(
            new Style({
                image: new Icon({
                    anchor: [0.5, 0.5],
                    scale : scale(markerIcons[i],zoom),
                    src: markerIcons[i]
                }),
                text: textStyle
            })
        );
        
        iconFeature.set('id', markerIds[i]);
        if(markerTypes){
            iconFeature.set('type', markerTypes[i]);
        }
        iconFeatures.push(iconFeature);
    }

    const vectorLayer = new VectorLayer({
        source: new VectorSource({
            features: iconFeatures
        })
    });
    return vectorLayer;
}

export const removeLayerByIds = (map : Map, layerIds : string[]) : void => {
    map.getLayers().getArray().filter(layer => layer && layerIds.includes(layer.get('id'))).forEach(layer => map.removeLayer(layer));
}

export type CircleDef = { coords : Coordinate, radius : number };

export const addCircle = (map : Map, coords : Coordinate, radius : number) : void =>{
    map.addLayer(circle([{coords : fromLonLat(coords), radius: radius}]));
}

export const circle = (circleDefs : CircleDef[]) : VectorLayer<VectorSource<Geometry>> =>  {

    const circleFeatures = circleDefs.map(c => new Feature({ geometry: new Circle(c.coords, c.radius) }));
    return new VectorLayer({
        source: new VectorSource({
          features: circleFeatures
        }),
        style : new Style({
            renderer: (coordinates : SketchCoordType, state : State) => {
                  const coordinates_0 = coordinates[0] as Coordinate;
                  const coordinates_1 = coordinates[1] as Coordinate;
    
                  const x = coordinates_0[0];
                  const y = coordinates_0[1];
    
    
                  const x1 = coordinates_1[0];
                  const y1 = coordinates_1[1];
                  const ctx = state.context;
                  const dx = x1 - x;
                  const dy = y1 - y;
                  const radius = Math.sqrt(dx * dx + dy * dy);
            
                  var innerRadius = 0;
                  var outerRadius = radius * 1.4;
            
                  var gradient = ctx.createRadialGradient(x,y,innerRadius,x,y,outerRadius);
    
                  gradient.addColorStop(0, 'rgba(255,0,0,0)');
                  gradient.addColorStop(0.6, 'rgba(255,0,0,0.2)');
                  gradient.addColorStop(1, 'rgba(255,0,0,0.8)');
                  ctx.beginPath();
                  ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
                  ctx.fillStyle = gradient;
                  ctx.fill();
            
                  ctx.arc(x, y, radius, 0, 2 * Math.PI, true);
                  ctx.strokeStyle = 'rgba(255,0,0,1)';
                  ctx.stroke();
            }
        })
    });
}

export interface PolygonParam {
    border : Coordinate[];
    polygonId : string;
    polygonType? : string;
}

export const polygon = (polygonParams : PolygonParam[]) : VectorLayer<VectorSource<Geometry>> =>  {
    const polygonFeatures = polygonParams.map(p => {
        const {border, polygonId, polygonType} = p;
        const feature : Feature<Geometry> = new Feature({ geometry: new Polygon([border]) });
        feature.set('id', polygonId);
        if(polygonType){
            feature.set('type', polygonType);
        }
        return feature;
    });

    return new VectorLayer({
        source: new VectorSource({
          features: polygonFeatures
        }),
        style : [
            /* We are using two different styles for the polygons:
             *  - The first style is for the polygons themselves.
             *  - The second style is to draw the vertices of the polygons.
             *    In a custom `geometry` function the vertices of a polygon are
             *    returned as `MultiPoint` geometry, which will be used to render
             *    the style.
             */
            new Style({
              stroke: new Stroke({
                color: 'white',
                width: 2,
              }),
              fill: new Fill({
                color: 'rgba(0, 255, 255, 0.1)',
              }),
            }),
            new Style({
              image: new CircleStyle({
                radius: 2,
                fill: new Fill({
                  color: 'white',
                }),
              }),
              geometry: function (feature) {
                // return the coordinates of the first ring of the polygon
                const coordinates = feature.getGeometry()?.getCoordinates()[0];
                return new MultiPoint(coordinates);
              },
            })
        ]
    });
}


/* ------------------------------------------------------  POPUP ------------------------------------------------------------------- */

export interface Popup{
    overlay : Overlay;
    content : HTMLElement;
}

export const buildPopup = () : Popup => {
    /**
    * Elements that make up the popup.
    */
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');

    if(!container || !closer ||!content){
        throw new Error("Html must have popup element.");
    }

    /**
    * Create an overlay to anchor the popup to the map.
    */
    const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
            duration: 250,
        },
    });

    /**
    * Add a click handler to hide the popup.
    */
    closer.onclick = function () {
        overlay.unset('position');
        closer.blur();
        return false;
    };

    return { overlay, content };
}

const getContentFromIds = (featureIds : string[]) : Promise<string> => {
    return Promise.resolve('<p>I ('+featureIds[0]+') need help !</p>');
}

export const openPopup = (popup : Popup, coordinate : Coordinate, featureIds : string[], contentFromIdsCallback? : (featureIds : string[]) => Promise<string>, errorCallBack? : (error : any)=> void) => {
    const hdms = toStringHDMS(toLonLat(coordinate));
    const contentFromIdsPromise = contentFromIdsCallback ? contentFromIdsCallback(featureIds) : getContentFromIds(featureIds);
    contentFromIdsPromise.then((contentFromIds) => {
        popup.content.innerHTML = '<p>location:</p><code>' + hdms + '</code><br/>'+contentFromIds;
        popup.overlay.setPosition(coordinate);
    }).catch(error => {
        errorCallBack?.(error);
    })
}

/* ------------------------------------------------------  Map listener ------------------------------------------------------------------- */

export const hasFeature = (map : Map, event : any) : boolean => {
    return map.hasFeatureAtPixel(event.pixel);
}

export const findFeatureIds = (map : Map, event : any) : string[] => {
    return map.getFeaturesAtPixel(event.pixel).map(feature => feature?.get('id'));
}

export const findFeatureTypes = (map : Map, event : any) : string[] => {
    return map.getFeaturesAtPixel(event.pixel).map(feature => feature?.get('type'));
}

export type MapCallbackMatch = (map : Map, featureIds: string[], featureTypes: string[], event : any) => void;
export type MapCallbackNoMatch = (map : Map, event : any) => void;

export interface MapEventCallback{
    eventName : string;
    callbackMatchs? : MapCallbackMatch[];
    callbackNoMatchs? : MapCallbackNoMatch[];
}

export const register = (map : Map, mapEventCallback : MapEventCallback) => {
    map.addEventListener(mapEventCallback.eventName, (event : any) : boolean => {
        if (hasFeature(map , event)) {
            const featureIds = findFeatureIds(map, event);
            const featureTypes = findFeatureTypes(map, event);
            mapEventCallback.callbackMatchs?.forEach(callbackMatch => callbackMatch(map, featureIds, featureTypes, event));
        }
        else{
            mapEventCallback.callbackNoMatchs?.forEach(callbackNoMatch => callbackNoMatch(map, event));
        }
        return false;
    });
}

export type MapGeometry = Coordinate[]; 

export type MapGeometryHandler =  (mapGeometry : MapGeometry) => Promise<void>;

export type MapGeometryCallback = (map : Map, interaction : Interaction, event : any, mapGeometry : MapGeometry, handler : MapGeometryHandler) => void;

export const buildPolygon = (map : Map, interaction : Interaction, event : any, mapGeometry : MapGeometry, handler : MapGeometryHandler) : void => {
    const coords = event.coordinate;
    if(!coords){
        return;
    }

    const gpsCoords = toLonLat(event.coordinate);
    mapGeometry.push(gpsCoords);

    const threshold = boxDiagonale(mapGeometry,0.2) /20;
    const cleanedGeometry = removeDuplicate(mapGeometry, threshold);

    if(isClosed(cleanedGeometry, threshold)){
        cleanedGeometry.pop();
        cleanedGeometry.push(cleanedGeometry[0]);

        handler(cleanedGeometry).then(v => map.removeInteraction(interaction));
    }
}

export const buildCircle = (map : Map, interaction : Interaction, event : any, mapGeometry : MapGeometry, handler : MapGeometryHandler) : void => {
    const coords = event.coordinate;
    if(!coords){
        return;
    }

    const gpsCoords = toLonLat(event.coordinate);
    mapGeometry.push(gpsCoords);

    if(mapGeometry.length == 2 ){

        handler(mapGeometry).then(v => map.removeInteraction(interaction));
    }
};

export const buildPoint = (map : Map, interaction : Interaction, event : any, mapGeometry : MapGeometry, handler : MapGeometryHandler) : void => {
    const coords = event.coordinate;
    if(!coords){
        return;
    }

    const gpsCoords = toLonLat(event.coordinate);
    mapGeometry.push(gpsCoords);

    if(mapGeometry.length == 1 ){

        handler(mapGeometry).then(v => map.removeInteraction(interaction));
    }
};


export interface MapGeometryEventCallback{
    eventName : string;
    mapGeometryCallback : MapGeometryCallback;
    mapGeometry : MapGeometry;
    handler : MapGeometryHandler;
}

export interface InteractionAndCallback{
    interaction: Interaction;
    mapGeometryEventCallback : MapGeometryEventCallback;
}

//https://openlayers.org/en/latest/examples/draw-features.html
//https://openlayers.org/en/latest/examples/polygon-styles.html
export const listen = (map : Map, interactionAndCallback: InteractionAndCallback) => {
    const {interaction, mapGeometryEventCallback} = interactionAndCallback;
    map.addInteraction(interaction);
    map.addEventListener(mapGeometryEventCallback.eventName, (event : any) : boolean => {
        mapGeometryEventCallback.mapGeometryCallback(map, interaction, event, mapGeometryEventCallback.mapGeometry,mapGeometryEventCallback.handler);
        return false;
    });
}

/* ------------------------------------------------------  Math ------------------------------------------------------------------- */

export const formatValue = (val : number) : number => {
    return Math.floor(val*1e6);
}

export const formatPoint = (coord : Coordinate) : Coordinate => {
    return [formatValue(coord[0]),formatValue(coord[1])];
}

export const formatPolygon = (coords : Coordinate[]) : Coordinate[] => {
    return coords.map(p => formatPoint(p));
}

export const add = (point : Coordinate, vector : Coordinate) : Coordinate => {
    return [point[0] + vector[0], point[1] + vector[1]];
}

interface CoordinateMap {
    [key: string]: Coordinate[];
}

export interface BarycenterAggregate {
    center: Coordinate;
    quantity : number;
}

export const mergeToBarycenter = (points : Coordinate[], bottomLeft : Coordinate, squareSide : number) : BarycenterAggregate[] => {
    const barycenters : BarycenterAggregate[] = [];
    const squares : CoordinateMap = {};
    points.forEach(point => {
        const indexLon = Math.floor((point[0]-bottomLeft[0])/squareSide);
        const indexLat = Math.floor((point[1]-bottomLeft[1])/squareSide);
        const key = `${indexLon}-${indexLat}`.toString();
        if(!squares[key]){
            squares[key] = [];
        }
        squares[key].push(point);
    });
    for (const k in squares) {
        const square_k = squares[k];
        barycenters.push({center : barycenter(square_k), quantity : square_k.length});
    }
    return barycenters;
}

export const barycenter = (points : Coordinate[]) : Coordinate => {
    const bary : Coordinate = points.reduce((p, c) => [p[0]+c[0],p[1]+c[1]]);
    const n = points.length;
    return [bary[0]/n, bary[1]/n];
}

export const middle = (p1 : Coordinate, p2 : Coordinate) : Coordinate => {
    return [(p1[0]+p2[0])/2, (p1[1]+p2[1])/2];
}

export const distance = (c1 : Coordinate, c2 : Coordinate) : number => {
    return Math.sqrt((c2[0] - c1[0])*(c2[0] - c1[0]) + (c2[1] - c1[1])*(c2[1] - c1[1]));
}

export const near = (c1 : Coordinate, c2 : Coordinate, threshold : number) : boolean => {
    return distance(c1,c2) <= threshold;
}

export const boxDiagonale = (geometry : MapGeometry, defaultValue : number) : number => {
    let min = [...geometry[0]];
    let max = [...geometry[0]];
    if(geometry.length<2){
        return defaultValue;
    }
    for(let i = 1; i< geometry.length; i++){
        const pi = geometry[i];
        if(pi[0] < min[0]){
            min[0] = pi[0];
        }
        if(pi[0] > max[0]){
            max[0] = pi[0];
        }
        if(pi[1] < min[1]){
            min[1] = pi[1];
        }
        if(pi[1] > max[1]){
            max[1] = pi[1];
        }
    }

    return distance(min, max);
}

export const removeDuplicate = (geometry : MapGeometry, threshold : number) : MapGeometry => {
    if(!geometry || geometry.length < 2){
        return geometry;
    }
    const cleanedGeometry : MapGeometry = [];
    cleanedGeometry.push(geometry[0]);
    for(let i = 0; i< geometry.length-1; i++){
        const pi = geometry[i];
        const pi_1 = geometry[i+1];
        console.log('distinct');
        if(!near(pi,pi_1,threshold)){
            cleanedGeometry.push(pi_1);
        }
    }
    return cleanedGeometry;
}

export const isClosed = (geometry : MapGeometry, threshold : number) : boolean => {
    if(!geometry || geometry.length < 4){
        return false;
    }
    const p0 = geometry[0];
    for(let i = 1; i< geometry.length; i++){
        const pi = geometry[i];
        if(near(p0,pi,threshold)){
            return true;
        }
    }
    return false;
}
