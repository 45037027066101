import React  from "react";
import './Scroll.css';


export const Scroll = (props : {maxHeight? : string , children : any}) : JSX.Element => {
    const { maxHeight } = props;
    return (
        <div className="scrollbar scrollbar-sunny-morning" style={{ maxHeight: maxHeight? maxHeight : '85vh' , width : '100%', padding : '0px', margin: '0px' }}>
            {React.Children.map(props.children || null, (child, i) => {
                return (<child.type {...child.props} key={'tab-panel-key-'+i}  />);
            })}
        </div>
    );
};
