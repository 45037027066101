import React  from "react";
import { newId } from "../../utils/IdUtils";
import { localUrl } from "../img/Img";
import './Pane.css';


export const Pane = (props : {row? : boolean, ratio? : '100' | '50' | '33' | '25', src? : string, radius? : string, className? : string, style? : any, children : any}) : JSX.Element => {
    const { row, style, src, radius, ratio, className } = props;

    let stl : any = { display : 'flex', flexDirection: row?'row':'column', justifyContent: 'center', alignItems: 'center' , width : (ratio?  `${ratio}%`.toString() : '100%') };
    stl = src ? {...stl, backgroundImage: `url(${localUrl(src)})`.toString(), backgroundRepeat: 'no-repeat', objectFit : 'cover' } : stl;
    stl = radius ? {...stl, borderRadius: radius } : stl;
    stl = style ? { ...stl,  ...style } : stl;
    let cln = `pane-${row?'row':'column'}`.toString();
    cln = className ? `${cln} ${className}`.toString() : 'pane';

    return (
        <div className={cln} style={stl}>
            {React.Children.map(props.children || null, (child, i) => {
                return (<child.type {...child.props} key={`pane-key-${i}-${newId()}`.toString()}  />);
            })}
        </div>
    );
};
