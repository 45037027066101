import React, { Fragment, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link  } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { newId } from "../../utils/IdUtils";
import { Connect } from "../connect/Connect";
import './NavPanel.css';

export interface NavPanelItem {
    id : string;
    title : string;
    path : string
    backgroundImage : string;
    backgroundRepeat : string;
    outline? : boolean;
    bold? : boolean;
    hidden? : boolean;
    out? : boolean;
}

/**
 * @author Christophe Convert
 * 
 */
const Navigation = (props: { items: NavPanelItem[], logo? : () => string}) : JSX.Element => {
  const {t} = useTranslation('common');

  return (
    <div className="nav-panel" >
      {props.logo && 
        <img className="nav-logo" src={`${process.env.PUBLIC_URL}/images/${props.logo()}`} />
      }
      {props.items.filter(item => !item.hidden).map((item,i) => (
        <Fragment key={`nav-panel-item-fragment-${i}-${newId()})`.toString()}> 
          { (item.outline && !item.out) &&
            <div key={`nav-panel-item-${i})`.toString()} className="nav-panel-item-bnt">
              <Link key={`nav-panel-item-${i})`.toString()} to={item.path}> <div className={item.bold ? 'nav-panel-item-bold': 'nav-panel-item'}> {t(item.title)}</div></Link>
            </div>
          }
          { (!item.outline && !item.out) &&
            <Link key={`nav-panel-item-${i})`.toString()} to={item.path}> <div className={item.bold ? 'nav-panel-item-bold': 'nav-panel-item'}> {t(item.title)}</div></Link>
          }
        </Fragment>
      ))}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Connect />
      </div>
    </div>
  );
}

const Screen = (props: { items: NavPanelItem[],  logo? : () => string, i: number, children : any }) : JSX.Element => (
    <div style={{ width: '100%',  minWidth: '100%',  minHeight : '100vh', padding: '0', margin: '0', zIndex: 0, backgroundImage: `url(${process.env.PUBLIC_URL}/images/${props.items[props.i].backgroundImage})`, backgroundRepeat: props.items[props.i].backgroundRepeat }}>
      <div style={{ width: '100%',  minWidth: '100%',  minHeight : '100vh', padding: '0', margin: '0',  zIndex: 1, background: "radial-gradient(circle, rgba(128, 128, 128, 0.2) 0%, rgba(0, 0, 0, 0.9) 100%) center center no-repeat"}}>
        <Navigation items={ props.items} logo={props.logo}  />
        <div className="mw-100 p-0 m-0 text-center content" style={{ backgroundColor: 'transparent' }}>
            {props.children}
        </div>
      </div>  
    </div>
);

const childNavPanel = (items: NavPanelItem[], index: number, visibility : (showIds : string[], hideIds : string[]) => void, children : any, logo? : () => string ) : JSX.Element => (
  <>
  {React.Children.map(children || null, (child, i) => {
    return (
      <>
        {(i === index) && (
          <Screen items={items} logo={logo} i={i} key={`nav-panel-child-${i})`.toString()}>
            <child.type {...child.props} visibility={visibility} key={'nav-key-'+index}  />
          </Screen>
        )}
      </>
    );
  })}
  </>
);

const childNavPanel2 = (items: NavPanelItem[],  index: number, visibility : (showIds : string[], hideIds : string[]) => void, children : any, logo? : () => string ) : JSX.Element => {
  const child : any = React.Children.toArray(children)[index];
  return (
    <Screen items={items} logo={logo}  i={index} key={`nav-panel-child-${index}`.toString()}>
      <child.type {...child.props} visibility={visibility} key={'nav-key-'+index}  />
    </Screen>
  );
};

const outChild = (items: NavPanelItem[],  index: number, children : any) : JSX.Element => {
  const child : any = React.Children.toArray(children)[index];
  return (
    <div style={{ width: '100%',  minWidth: '100%',  minHeight : '100vh', padding: '0', margin: '0', zIndex: 0, backgroundImage: `url(${process.env.PUBLIC_URL}/images/${items[index].backgroundImage})`, backgroundRepeat: 'repeat' }}>
      <div style={{ width: '100%',  minWidth: '100%',  minHeight : '100vh', padding: '0', margin: '0',  zIndex: 1, background: "radial-gradient(circle, rgba(128, 128, 128, 0.2) 0%, rgba(0, 0, 0, 0.9) 100%) center center no-repeat"}}>
        <child.type {...child.props} key={'nav-key-out-'+index}  />
      </div>
    </div>
  );
};

export interface NavPanelState {
  items: NavPanelItem[];
};

const NavPanel = (props: { items: NavPanelItem[], children : any, logo? : () => string }) : JSX.Element => {
    const  { children, logo } = props;
    const [ navPanelState, setNavPanelState] = useState<NavPanelState>({ items: props.items});
    
    const  { items } = navPanelState;
    const visibility = (showIds : string[], hideIds : string[]) : void => {
      const updItems = items.map(item => {
        if(showIds.includes(item.id)){
          return ({...item, hidden: false});
        }
        else if(hideIds.includes(item.id)){
          return ({...item, hidden: true});
        }
        else {
          return item;
        }
      });
      setNavPanelState({ items: updItems});
    };

    
    return (
        <div style={{ width: '100%',  minWidth: '100%',  minHeight : '100vh', padding: '0', margin: '0'}}>
          <Router>
            <Routes>
              <Route path="/" element = {childNavPanel2(items, 0, visibility, children, logo)} key={`nav-panel-route--`.toString()}/>
              {items.map((item, i) => (
                <Fragment key={`nav-panel-route-${i}`.toString()}>
                  {item.out ?
                    <Route path={item.path} element={outChild(items, i, children)} key={`nav-panel-route-out-${i})`.toString()} />
                  :
                    <Route path={item.path} element = {childNavPanel2(items, i, visibility, children, logo)} key={`nav-panel-route-${i})`.toString()}/>
                  }
                </Fragment>
              ))}
            </Routes>
          </Router>
        </div>
    );
};

export  {NavPanel};
