import React from "react";
import { newId } from "../../utils/IdUtils";


export const localUrl = (fileId: string) : string => {
    return `${process.env.PUBLIC_URL}/${fileId}`.toString();
 };

export const Img = (props: { src: string, className: string, style?: any, onClick? : () => void}) : JSX.Element => {
    const { src, className, style, onClick} = props;

    return (
        <img 
            key={`img-${newId()}`.toString()}
            style={style ? style : {}}
            className={className}
            src={localUrl(src)}
            alt=""
            onClick = {() => {if(onClick){onClick();}}}
        /> 
    );
};
