/**
 * @author Christophe Convert
 * 
 */

// ########################################## INFURA ####################################################################

export const INFURA_ID = process.env.REACT_APP_INFURA_ID ? process.env.REACT_APP_INFURA_ID : '0000';

// ########################################## CHAIN ID & ETHERSCAN ####################################################

export const ETHERSCAN_CHAIN_ID = process.env.REACT_APP_ETHERSCAN_CHAIN_ID ? parseInt(process.env.REACT_APP_ETHERSCAN_CHAIN_ID,10) : 11155111;
export const ETHERSCAN_TX_URL = process.env.REACT_APP_ETHERSCAN_TX_URL ? process.env.REACT_APP_ETHERSCAN_TX_URL : 'https://sepolia.etherscan.io/tx/';

// ########################################## UNISWAP ####################################################################

export const UNISWAP_URL= process.env.REACT_APP_UNISWAP_URL ? process.env.REACT_APP_UNISWAP_URL : ''; 

// ########################################## JURAKKO ERC20 #############################################################

export const JURAKKO_ERC20_ADDRESS = process.env.REACT_APP_JURAKKO_ERC20_ADDRESS ? process.env.REACT_APP_JURAKKO_ERC20_ADDRESS : '';
export const JURAKKO_ERC20_ABI = process.env.REACT_APP_JURAKKO_ERC20_ABI ? JSON.parse(process.env.REACT_APP_JURAKKO_ERC20_ABI) : '';
export const JURAKKO_ERC20_SYMBOL = process.env.REACT_APP_JURAKKO_ERC20_SYMBOL ? process.env.REACT_APP_JURAKKO_ERC20_SYMBOL : '';
export const JURAKKO_ERC20_DECIMALS = process.env.REACT_APP_JURAKKO_ERC20_DECIMALS ? process.env.REACT_APP_JURAKKO_ERC20_DECIMALS : 18;
export const JURAKKO_ERC20_IMAGE = process.env.REACT_APP_JURAKKO_ERC20_IMAGE ? process.env.REACT_APP_JURAKKO_ERC20_IMAGE : 'images/jurakko.png';

// ########################################## JURAKKO STAKING #################################################

export const JURAKKO_STAKING_ADDRESS = process.env.REACT_APP_JURAKKO_STAKING_ADDRESS ? process.env.REACT_APP_JURAKKO_STAKING_ADDRESS : '';
export const JURAKKO_STAKING_ABI = process.env.REACT_APP_JURAKKO_STAKING_ABI ? JSON.parse(process.env.REACT_APP_JURAKKO_STAKING_ABI) : '';

// ########################################## JURAKKO BOOKING #################################################

export const JURAKKO_BOOKING_ADDRESS = process.env.REACT_APP_JURAKKO_BOOKING_ADDRESS ? process.env.REACT_APP_JURAKKO_BOOKING_ADDRESS : '';
export const JURAKKO_BOOKING_ABI = process.env.REACT_APP_JURAKKO_BOOKING_ABI ? JSON.parse(process.env.REACT_APP_JURAKKO_BOOKING_ABI) : '';

// ########################################## JURAKKO BILL ####################################################

export const JURAKKO_BILL_ADDRESS = process.env.REACT_APP_JURAKKO_BILL_ADDRESS ? process.env.REACT_APP_JURAKKO_BILL_ADDRESS : '';
export const JURAKKO_BILL_ABI = process.env.REACT_APP_JURAKKO_BILL_ABI ? JSON.parse(process.env.REACT_APP_JURAKKO_BILL_ABI) : '';

// ########################################## JURAKKO ASSET MARKETPLACE #################################################

export const JURAKKO_ASSET_MARKETPLACE_ADDRESS = process.env.REACT_APP_JURAKKO_ASSET_MARKETPLACE_ADDRESS ? process.env.REACT_APP_JURAKKO_ASSET_MARKETPLACE_ADDRESS : '';
export const JURAKKO_ASSET_MARKETPLACE_ABI = process.env.REACT_APP_JURAKKO_ASSET_MARKETPLACE_ABI ? JSON.parse(process.env.REACT_APP_JURAKKO_ASSET_MARKETPLACE_ABI) : '';





