

export const largeScreen = () : boolean => {
    return window.screen.availWidth >= 1020;
};

export const getScrollHeight = (largeScreenHeight : string, smallScreenHeight : string) : string => {
    if(window.screen.availWidth <= 819){
      return largeScreenHeight;
    }
    else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
      return smallScreenHeight;
    }
    else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
      return smallScreenHeight;
    }
    else{
      return smallScreenHeight;
    }
};

