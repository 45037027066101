
/**
 * @author Christophe Convert
 * 
 */
export const arrayToMatrices = (data : any[], nbCols : number, nbRows : number) : any[][][] => {
    const nbElementByItem = nbCols * nbRows;
    let picturesInItems=[];
    for(let i=0 ; i < data.length; i+=nbElementByItem){ 
      let picturesInItem=[];
      let picturesInItemBucket=data.slice(i,i + nbElementByItem);
      for(let j=0 ; j < nbElementByItem; j+= nbRows){ 
        let picturesInItemCol=picturesInItemBucket.slice(j,j + nbRows);
        picturesInItem.push(picturesInItemCol);
      }
      picturesInItems.push(picturesInItem);
    }
    return picturesInItems;
};

export const findElementMatrixIndex = (matrices : any[][][], id : number, getId : (element : any) => number) : number => {
    let index=1;
    for(let i=0 ; i < matrices.length; i+=1){ 
      let matrix = matrices[i];
      for(let j=0 ; j < matrix.length; j+= 1){ 
        if(matrix[j].find(picture => getId(picture) === id)){
            index=i+1;
        }
      }
    }
    return index;
};

export const merge = (a : any, b : any, predicate = (a : any, b : any) => a === b) : any[] => {
  const c = [...a]; 
  b.forEach((bItem : any) => (c.some((cItem : any) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}
