import React from "react";
import { useTranslation } from "react-i18next";
import { RoundButton } from "../../component/button/round/RoundButton";
import { Img, localUrl } from "../../component/img/Img";
import { Pane } from "../../component/pane/Pane";
import { Scroll } from "../../component/scroll/Scroll";
import { Text } from "../../component/text/Text";
import { largeScreen } from "../../utils/ScreenUtils";
import './Goals.css';

/**
 * @author Christophe Convert
 * 
 */
 export const Goals = (props : {}) : JSX.Element => {
    const {t} = useTranslation('common');

    const getLargeMargin = () : string => {
        if(window.screen.availWidth <= 819){
          return '20vw';
        }
        else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
          return '14vw';
        }
        else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
          return '10vw';
        }
        else{
          return '10vw';
        }
    };

    const getMargin = () : string => {
        if(window.screen.availWidth <= 819){
          return '10vw';
        }
        else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
          return '7vw';
        }
        else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
          return '5vw';
        }
        else{
          return '5vw';
        }
    };

    const stepMargin = largeScreen() ? '2vw' : '1vw';

    const height = largeScreen() ? '50vw' : '60vw';
    const heightStepXl = largeScreen() ? '45vw' : '60vw';

    return(
        <Scroll maxHeight="90vh">
            <Pane src="goals/mountain1.jpg">
                <Text content={t('page.goals.introduction1')} style={{ marginTop : getLargeMargin()}} size="lg" theme="light" />
                <Text content={t('page.goals.introduction2')} style={{ marginBottom : getLargeMargin()}} size="lg" theme="light" />
            </Pane>
            
            {largeScreen() ? 
                <Pane row={true}>
                    <Pane ratio="50" style={{ height, backgroundColor : 'white'}}>
                        <Text content={t('page.goals.presentation1')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation2')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation3')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation4')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation5')} style={{marginBottom : '1vw'}} size="md" theme="dark" />
                        <RoundButton title={t('page.goals.buyOnUniswap')} reverse={true} onClick = {() => {}}/>
                    </Pane>
                    <Pane ratio="50" style={{  height, backgroundColor : '#17824e'}}>
                        <Text content={t('page.goals.description1')} size="md" theme="light" />
                        <Text content={t('page.goals.description2')} size="md" theme="light" />
                        <Text content={t('page.goals.description3')} style={{marginBottom : '1vw'}} size="md" theme="light" />
                        <RoundButton title={t('page.goals.viewProjects')}  onClick = {() => {}}/>
                    </Pane>
                </Pane>
                :
                <>
                    <Pane ratio="100" style={{ height, backgroundColor : 'white'}}>
                        <Text content={t('page.goals.presentation1')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation2')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation3')} size="md" theme="dark" />
                        <Text content={t('page.goals.presentation4')} style={{marginBottom : '1vw'}} size="md" theme="dark" />
                        <RoundButton title={t('page.goals.buyOnUniswap')} reverse={true} onClick = {() => {}}/>
                    </Pane>
                    <Pane ratio="100" style={{  height, backgroundColor : '#17824e'}}>
                        <Text content={t('page.goals.description1')} size="md" theme="light" />
                        <Text content={t('page.goals.description2')} size="md" theme="light" />
                        <Text content={t('page.goals.description3')} style={{marginBottom : '1vw'}} size="md" theme="light" />
                        <RoundButton title={t('page.goals.viewProjects')}  onClick = {() => {}}/>
                    </Pane>
                </>
            }
            <Text content={t('page.goals.creation-title')} style={{ marginTop : getLargeMargin(), marginBottom : getLargeMargin()}} size="lg" theme="light" />
            {largeScreen() ? 
                <Pane row={true} >
                    <Pane ratio="50" style={{ height, backgroundColor : '#a4efcb'}}>
                        <Text content={t('page.goals.creation1')} style={{marginBottom : '1vw'}} size="md" bold={true} theme="dark" />
                        <Text content={t('page.goals.creation2')} size="md" theme="dark" />
                        <Text content={t('page.goals.creation3')} size="md" theme="dark" />
                        <Text content={t('page.goals.creation4')} size="md" theme="dark" />
                    </Pane>
                    <Pane ratio="50" style={{  height, backgroundColor : '#a4efcb'}}>
                        <Img src="images/goals/booking-hotel-room.png" style={{marginRight : '2vw'}} className="goals-img" />
                    </Pane>
                </Pane>
                :
                <>
                    <Pane ratio="100" style={{ height, backgroundColor : 'white'}}>
                        <Text content={t('page.goals.creation1')} style={{marginBottom : '1vw'}} size="md" bold={true} theme="dark" />
                        <Text content={t('page.goals.creation2')} size="md" theme="dark" />
                        <Text content={t('page.goals.creation3')} size="md" theme="dark" />
                        <Text content={t('page.goals.creation4')} size="md" theme="dark" />
                    </Pane>
                    <Pane ratio="100" style={{  height, backgroundColor : '#a4efcb'}}>
                        <Img src="images/goals/booking-hotel-room.png" style={{marginRight : '2vw'}} className="goals-img" />
                    </Pane>
                </>
            }
            
            {largeScreen() ? 
                <Pane row={true}>
                    <Pane ratio="50" style={{  height, backgroundColor : '#a4efcb'}}>
                        <Img src="images/goals/booking-hotels.png" style={{marginRight : '2vw'}} className="goals-img" />
                    </Pane>
                    <Pane ratio="50" style={{ height, backgroundColor : '#a4efcb'}}>
                        <Text content={t('page.goals.booking1')} style={{marginBottom : '1vw'}} size="md" bold={true} theme="dark" />
                        <Text content={t('page.goals.booking2')} size="md" theme="dark" />
                        <Text content={t('page.goals.booking3')} size="md" theme="dark" />
                        <Text content={t('page.goals.booking4')} size="md" theme="dark" />
                        <Text content={t('page.goals.booking5')} size="md" theme="dark" />
                    </Pane>
                </Pane>
                :
                <>
                    <Pane ratio="100" style={{ height, backgroundColor : 'white'}}>
                        <Text content={t('page.goals.booking1')} style={{marginBottom : '1vw'}} size="md" bold={true} theme="dark" />
                        <Text content={t('page.goals.booking2')} size="md" theme="dark" />
                        <Text content={t('page.goals.booking3')} size="md" theme="dark" />
                        <Text content={t('page.goals.booking4')} size="md" theme="dark" />
                        <Text content={t('page.goals.booking5')} size="md" theme="dark" />
                    </Pane>
                    <Pane ratio="100" style={{  height, backgroundColor : '#a4efcb'}}>
                        <Img src="images/goals/booking-hotels.png" style={{marginRight : '2vw'}} className="goals-img" />
                    </Pane>
                </>
            }
            <Text content={t('page.goals.step-title')} style={{ marginTop : getLargeMargin(), marginBottom : getLargeMargin()}} size="lg" theme="light" />
            {largeScreen() ? 
                <Pane style={{ backgroundColor : 'white'}}>
                    <Pane row={true}>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-end'}}>
                            <Img src="images/goals/metamask.png" style={{marginRight : stepMargin}} className="goals-step" />
                        </Pane>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-start'}}>
                            <Text content={t('page.goals.step1')} style={{marginLeft : stepMargin}} size="md" bold={true} theme="dark" />
                        </Pane>
                    </Pane>
                    <Pane row={true}>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-end'}}>
                            <Text content={t('page.goals.step2')} style={{marginRight : stepMargin}} size="md" bold={true} theme="dark" />
                        </Pane>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-start'}}>
                            <Img src="images/goals/booking-criteria.png" style={{marginLeft : stepMargin}} className="goals-step-xl" />
                        </Pane>
                    </Pane>
                    <Pane row={true}>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-end'}}>
                            <Img src="images/goals/booking-book.png" style={{marginRight : stepMargin}} className="goals-step-xl" />
                        </Pane>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-start'}}>
                            <Text content={t('page.goals.step3')} style={{marginLeft : stepMargin}} size="md" bold={true} theme="dark" />
                        </Pane>
                    </Pane>
                    <Pane row={true}>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-end'}}>
                            <Text content={t('page.goals.step4')} style={{marginRight : stepMargin}} size="md" bold={true} theme="dark" />
                        </Pane>
                        <Pane row={true} ratio="50" style={{ height : heightStepXl, justifyContent : 'flex-start'}}>
                            <Img src="images/goals/booking-nft.png" style={{marginLeft : stepMargin}} className="goals-step-xl" />
                        </Pane>
                    </Pane>
                </Pane>
            :
                <Pane style={{ backgroundColor : 'white'}}>
                    <Text content={t('page.goals.step1')} style={{marginTop : stepMargin, marginBottom : stepMargin}} size="md" bold={true} theme="dark" />
                    <Img src="images/goals/metamask.png" style={{marginTop : stepMargin, marginBottom : stepMargin}} className="goals-img" />
                    <Text content={t('page.goals.step2')} style={{marginTop : stepMargin, marginBottom : stepMargin}} size="md" bold={true} theme="dark" />
                    <Img src="images/goals/booking-criteria.png" style={{marginTop : stepMargin, marginBottom : stepMargin}} className="goals-step-xl" />
                    <Text content={t('page.goals.step3')} style={{marginTop : stepMargin, marginBottom : stepMargin}} size="md" bold={true} theme="dark" />
                    <Img src="images/goals/booking-book.png" style={{marginTop : stepMargin, marginBottom : stepMargin}} className="goals-step-xl" />
                    <Text content={t('page.goals.step4')} style={{marginTop : stepMargin, marginBottom : stepMargin}} size="md" bold={true} theme="dark" />
                    <Img src="images/goals/booking-nft.png" style={{marginTop : stepMargin, marginBottom : stepMargin}} className="goals-step-xl" />
                </Pane>
            }
        </Scroll>
    );

 }