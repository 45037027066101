import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RoundButton } from "../../component/button/round/RoundButton";
import { Img } from "../../component/img/Img";
import { Scroll } from "../../component/scroll/Scroll";
import { Text } from "../../component/text/Text";
import './Docs.css';

/**
 * @author Christophe Convert
 * 
 */
 export const Docs = (props : {}) : JSX.Element => {
    const {t} = useTranslation('common');

    const getLargeMargin = () : string => {
      if(window.screen.availWidth <= 819){
        return '20vw';
      }
      else if (window.screen.availWidth >= 820 && window.screen.availWidth <= 1019){
        return '14vw';
      }
      else if (window.screen.availWidth >= 1020 && window.screen.availWidth <= 1919){
        return '10vw';
      }
      else{
        return '10vw';
      }
    };

    return(
        <Scroll maxHeight="90vh">
            <Text content={t('page.docs.introduction')} style={{ marginTop : getLargeMargin(), marginBottom : getLargeMargin()}} size="lg" theme="light" />
        </Scroll>
    );

 }